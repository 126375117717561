import i18n from '../../../i18n'

export enum DrinkType {
  None,
  Water,
  Coffee,
  FreshDrink,
  Alcohol,
}

export enum EmptyingQuantity {
  Fifty = '50',
  Hundred = '100',
  HundredFifty = '150',
  TwoHundred = '200',
  TwoHundredFifty = '250',
  ThreeHundred = '300',
  ThreeHundredFifty = '350',
  FourHundred = '400',
  FourHundredFifty = '450',
  FiveHundred = '500',
  FiveHundredFifty = '550',
  SixHundred = '600',
  SixHundredFifty = '650',
  SevenHundred = '700',
  SevenHundredFifty = '750',
  EightHundred = '800',
  EightHundredFifty = '850',
}

export const emptyingQuantity = (): Record<EmptyingQuantity, string> => ({
  [EmptyingQuantity.Fifty]: '50 ml',
  [EmptyingQuantity.Hundred]: '100 ml',
  [EmptyingQuantity.HundredFifty]: '150 ml',
  [EmptyingQuantity.TwoHundred]: '200 ml',
  [EmptyingQuantity.TwoHundredFifty]: '250 ml',
  [EmptyingQuantity.ThreeHundred]: '300 ml',
  [EmptyingQuantity.ThreeHundredFifty]: '350 ml',
  [EmptyingQuantity.FourHundred]: '400 ml',
  [EmptyingQuantity.FourHundredFifty]: '450 ml',
  [EmptyingQuantity.FiveHundred]: '500 ml',
  [EmptyingQuantity.FiveHundredFifty]: '550 ml',
  [EmptyingQuantity.SixHundred]: '600 ml',
  [EmptyingQuantity.SixHundredFifty]: '650 ml',
  [EmptyingQuantity.SevenHundred]: '700 ml',
  [EmptyingQuantity.SevenHundredFifty]: '750 ml',
  [EmptyingQuantity.EightHundred]: '800 ml',
  [EmptyingQuantity.EightHundredFifty]: '850 ml',
})

export const drinkType = (): Record<DrinkType, string> => ({
  [DrinkType.Water]: i18n.t('water'),
  [DrinkType.Coffee]: i18n.t('coffee'),
  [DrinkType.FreshDrink]: i18n.t('freshDrink'),
  [DrinkType.Alcohol]: i18n.t('alcohol'),
  [DrinkType.None]: i18n.t('none'),
})

export const emptying = (): Record<Emptying, string> => ({
  [Emptying.Urine]: i18n.t('urine'),
  [Emptying.Catheter]: i18n.t('catheter'),
  [Emptying.None]: i18n.t('none'),
})

export const drinkQuantity = (): Record<Quantity, string> => ({
  [Quantity.Glass]: '150 ml',
  [Quantity.Cup]: '200 ml',
  [Quantity.Tin]: '330 ml',
  [Quantity.Bottle]: '500 ml',
})

export const drinkQuantityWithoutML = (): Record<Quantity, string> => ({
  [Quantity.Glass]: '150',
  [Quantity.Cup]: '200',
  [Quantity.Tin]: '330',
  [Quantity.Bottle]: '500',
})

export const foodTypes = (): Record<FoodType, string> => ({
  [FoodType.Soups]: i18n.t('soups'),
  [FoodType.Vegetables]: i18n.t('vegetables'),
  [FoodType.Fruits]: i18n.t('fruits'),
  [FoodType.Other]: i18n.t('other'),
})

export const urgencyType = (): Record<Urgency, string> => ({
  [Urgency.NoUrgency]: i18n.t('noUrgency'),
  [Urgency.Low]: i18n.t('low'),
  [Urgency.Regular]: i18n.t('regular'),
  [Urgency.High]: i18n.t('high'),
  [Urgency.Critical]: i18n.t('critical'),
})

export const incontinenceType = (): Record<Incontinence, string> => ({
  [Incontinence.UrgencyIncontinence]: i18n.t('urgencyIncontinence'),
  [Incontinence.EffortIncontinence]: i18n.t('effortIncontinence'),
})

export enum Quantity {
  Glass,
  Cup,
  Tin,
  Bottle,
}

export enum FoodType {
  Soups,
  Vegetables,
  Fruits,
  Other,
}

export enum Food {
  Peas,
  MandarinOrange,
}

export enum Emptying {
  None,
  Urine,
  Catheter,
}

export enum Urgency {
  NoUrgency,
  Low,
  Regular,
  High,
  Critical,
}

export enum Loss {
  Yes,
  No,
}

export const lossType = (): Record<Incontinence, string> => ({
  [Loss.Yes]: i18n.t('yes'),
  [Loss.No]: i18n.t('no'),
})

export enum Incontinence {
  UrgencyIncontinence,
  EffortIncontinence,
}
