import { Box } from '@mui/material'
import generic from '../../assets/generic.module.css'
import style from '../statistics/Stats.module.css'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { Query, QueryParam, SortParam } from '../../common/api/Query'
import { Fecal } from '../../modules/fecal/models/Fecal'
import { getFecalContainer } from '../../container/fecal-module'
import { FecalService } from '../../modules/fecal/services/FecalService'
import { FECAL_SERVICE_KEY } from '../../modules/fecal'
import { AppTable, Field } from '../../components/table'
import { Pager, Sort } from '../../components/table/types'
import {
  fecesConsistency,
  fecesQuantity,
  method,
  timeString,
  urgency,
} from '../../modules/fecal/enum/Feces'
import { navigate } from '@reach/router'
import { ROUTE_PATIENTS_ID } from 'routes/routes-constants'
import { foodTypes } from 'modules/urination/enum/Urine'
import {
  IconButton,
  Avatar,
  Modal,
  Backdrop,
  Fade,
  makeStyles,
  createStyles,
} from '@material-ui/core'
import viewIcon from '../../assets/higea/ico-view.svg'
import CloseIcon from '@material-ui/icons/Close'
import { FecalDTO, fromModel } from '../../modules/fecal/models/FecalDTO'

type FecalProps = {
  id: string
}

const fecalService = getFecalContainer().get<FecalService>(FECAL_SERVICE_KEY)

export const Table = (props: FecalProps) => {
  const { t } = useTranslation()
  const dateMinusWeek = new Date()
  dateMinusWeek.setDate(dateMinusWeek.getDate() - 7)
  const [imagesModalOpened, setImagesModalOpened] = useState<boolean>(false)
  const [startDate, setStartDate] = useState<Date>(dateMinusWeek)
  const [finishDate, setFinishDate] = useState<Date>(new Date())
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [pager, setPager] = useState<Pager>()
  const [page, setPage] = useState<number>(0)
  const [items, setItems] = useState<Fecal[]>([])
  const [count, setCount] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [sort, setSort] = useState<SortParam<Fecal>>({
    field: 'date',
    desc: true,
  })
  const [currentFecalImage, setCurrentFecalImage] = useState<string>('')

  const validDate = (date: Date) => {
    let dateO = new Date(date.getFullYear(), date.getMonth(), date.getDate())
    if (dateO >= startDate && dateO <= finishDate) {
      return true
    }
    return false
  }

  const handleSeeImage = (ur: Fecal) => {
    if (ur.image.length > 0) {
      setCurrentFecalImage(ur.image)
    }
    setImagesModalOpened(true)
  }

  useEffect(() => {
    fecalService
      .getFilteredList(
        new Query({
          pager: { offset: page * rowsPerPage, limit: rowsPerPage },
          query: [new QueryParam<Fecal>('PatientID', props.id || '')],
          sort: [{ field: sort.field, desc: sort.desc }],
        })
      )
      .subscribe((res) => {
        let filteredItems: Fecal[] = []
        res?.items.forEach((item) => {
          if (validDate(new Date(item.date))) {
            filteredItems.push(item)
          }
        })
        setItems(filteredItems)
        setCount(filteredItems.length)
        setIsLoading(false)
      })
  }, [isLoading, startDate, finishDate])

  useEffect(() => {
    if (!isLoading) return
    setPager({
      page,
      count,
      handleChangePage,
      rowsPerPage,
      handleChangeRowsPerPage,
    })
    setIsLoading(false)
  }, [page, count, rowsPerPage])

  const sortable: Sort<Fecal> = {
    name: sort.field,
    direction: sort.desc ? 'desc' : 'asc',
    handleSort: (field) => {
      setSort({ field: field, desc: sort.field === field ? !sort.desc : true })
      setIsLoading(!isLoading)
    },
  }

  const fields: Field<Fecal>[] = [
    {
      searchable: true,
      sortable: true,
      label: t('dateAndTime'),
      name: 'date',
      renderFunc: (f, i) =>
        new Date(i.date).toLocaleString('es', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        }),
    },
    {
      searchable: true,
      sortable: true,
      label: t('attempts'),
      name: 'attempts',
    },
    {
      searchable: true,
      sortable: true,
      label: t('effectiveness'),
      name: 'effectiveness',
    },
    {
      searchable: true,
      sortable: true,
      label: t('quantity'),
      name: 'quantity',
      renderFunc: (f, i) => fecesQuantity()[i.quantity],
    },
    {
      searchable: true,
      sortable: true,
      label: t('loss'),
      name: 'loss',
    },
    {
      searchable: true,
      sortable: true,
      label: t('consistencyStool'),
      name: 'consistency',
      renderFunc: (f, i) => fecesConsistency()[i.consistency],
    },
    {
      searchable: true,
      sortable: true,
      label: t('food'),
      name: 'food',
      renderFunc: (f, i) => foodTypes()[i.foodType],
    },
    {
      searchable: true,
      sortable: true,
      label: t('Diaper'),
      name: 'diaper',
      renderFunc: (f, i) => (i.diaper ? t('yes') : t('no')),
    },
    {
      searchable: true,
      sortable: true,
      label: t('gas'),
      name: 'gas',
      renderFunc: (f, i) => (i.gas ? t('yes') : t('no')),
    },
    {
      searchable: false,
      sortable: false,
      label: t('urgency'),
      name: 'urgency',
      renderFunc: (f, i) => urgency()[i.urgency],
    },
    {
      searchable: false,
      sortable: false,
      label: t('method'),
      name: 'method',
      renderFunc: (f, i) => method()[i.time],
    },
    {
      searchable: false,
      sortable: false,
      label: t('time'),
      name: 'time',
      renderFunc: (f, i) => timeString()[i.time],
    },

    {
      searchable: true,
      sortable: true,
      label: t('image'),
      name: 'image',
      renderFunc: (f, i) =>
        i.image ? (
          <>
            {' '}
            <IconButton key={i.id} id={'idFecal'} onClick={() => handleSeeImage(i)}>
              <Avatar src={viewIcon} style={{ width: 30, height: 25 }} />
            </IconButton>
          </>
        ) : (
          <></>
        ),
    },
    {
      searchable: true,
      sortable: true,
      label: t('observations'),
      name: 'observations',
    },
  ]

  const handleChangePage = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) =>
    setRowsPerPage(Number.parseInt(event.target.value) || 10)

  const handleDate = (e: MaterialUiPickersDate, name: string) => {
    if (e && name == 'startDate') {
      let d = e.toDate()
      setStartDate(new Date(d.getFullYear(), d.getMonth(), d.getDate()))
      setIsLoading(!isLoading)
    }
    if (e && name == 'finishDate') {
      let d = e.toDate()
      setFinishDate(new Date(d.getFullYear(), d.getMonth(), d.getDate()))
      setIsLoading(!isLoading)
    }
  }

  const handleClean = () => {
    setFinishDate(new Date())
    const dateMinusWeek = new Date()
    dateMinusWeek.setDate(dateMinusWeek.getDate() - 7)
    setStartDate(dateMinusWeek)
  }

  const handleDownloadExcel = () => {
    const itemsDTO: FecalDTO[] = []
    items.forEach((item) => {
      itemsDTO.push(fromModel(item))
    })

    const language = navigator.language.split('-')[0].toUpperCase()
    fecalService.getExcelFromFecalDiaries(itemsDTO, language).subscribe((res) => {
      if (res) {
        const url = window.URL.createObjectURL(res)
        const a = document.createElement('a')
        a.href = url
        a.download = 'fecal-diaries.xlsx' // Nombre del archivo a descargar
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
      }
    })
  }

  const useStyles = makeStyles(() =>
    createStyles({
      componentStyle: {
        '& .MuiIconButton-root': {
          padding: 0,
        },
      },
    })
  )

  const classes = useStyles()

  return (
    <>
      <Box className={style.buttonBack}>
        <AppButton
          theme={ButtonTheme.NewPrimaryLightWithoutWidth}
          type={'button'}
          label={t('returnToPatient')}
          handler={() => navigate(ROUTE_PATIENTS_ID.replace(':id', `${props.id}`))}
        />
      </Box>
      <Box className={style.titleBox}>
        <h3 className={style.title}>{t('fecalMonitoring')}</h3>
      </Box>
      <Box className={generic.pageContainer}>
        <Box className={style.filtersContainer}>
          <Box className={style.titleContainer}>
            <h4 style={{ fontSize: '20px', color: '#000', fontWeight: 'normal' }}>
              {t('filters')}
            </h4>
          </Box>
          <Box className={style.filtersDetailContainer}>
            <Box display="flex" flexDirection="row" alignItems={'center'}>
              <h5 style={{ color: '#000' }}>{t('from')}</h5>
              <KeyboardDatePicker
                style={{ width: '150px', marginLeft: '10px', marginRight: '10px' }}
                InputProps={{
                  className: classes.componentStyle,
                  style: { fontFamily: 'Open-sans, sans-serif' },
                }}
                key={'startDate'}
                id={'startDate'}
                autoOk
                variant="inline"
                inputVariant={'outlined'}
                label={''}
                format="DD/MM/YYYY"
                value={startDate}
                onChange={(e) => handleDate(e, 'startDate')}
                size={'small'}
              />
              <h5 style={{ color: '#000' }}>{t('to')}</h5>
              <KeyboardDatePicker
                style={{ width: '150px', marginLeft: '10px' }}
                InputProps={{
                  className: classes.componentStyle,
                  style: { fontFamily: 'Open-sans, sans-serif' },
                }}
                key={'finishDate'}
                id={'finishDate'}
                autoOk
                variant="inline"
                inputVariant={'outlined'}
                label={''}
                format={'DD/MM/YYYY'}
                value={finishDate}
                onChange={(e) => handleDate(e, 'finishDate')}
                size={'small'}
              />
            </Box>
            <Box className={style.secondRow} style={{ marginBottom: '30px' }}>
              <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <Box className={style.buttonContainer}>
                  <AppButton
                    theme={ButtonTheme.BasicTrasparent}
                    type="button"
                    label={t('cleanFilters')}
                    handler={handleClean}
                  />
                </Box>
                <Box>
                  <AppButton
                    theme={ButtonTheme.NewPrimaryLight}
                    type="button"
                    label={t('apply')}
                    handler={() => {}}
                  />
                </Box>
                <Box ml={2}>
                  <AppButton
                    theme={ButtonTheme.NewPrimaryLightWithoutWidth}
                    type="button"
                    label={t('downloadTable')}
                    handler={handleDownloadExcel}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <AppTable items={items} pager={pager} fields={fields} rowKeyField={'id'} sort={sortable} />
      </Box>
      <Modal
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={imagesModalOpened}
        onClose={() => setImagesModalOpened(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade
          in={imagesModalOpened}
          timeout={500}
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '35%',
          }}
        >
          <div>
            <img
              src={currentFecalImage}
              alt="Fecal image"
              style={{ height: '38%', width: '34%' }}
            />
            <CloseIcon
              color="primary"
              style={{
                marginLeft: '-0.7%',
                marginTop: '-0.6%',
                backgroundColor: 'grey',
                color: 'white',
                borderRadius: 12,
              }}
              onClick={() => setImagesModalOpened(false)}
            />
          </div>
        </Fade>
      </Modal>
    </>
  )
}
