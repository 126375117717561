import React, { useEffect, useState } from 'react'
import { Avatar, Box, Button, Divider, Grid, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { navigate } from '@reach/router'
import {
  ROUTE_APPOINTMENTS,
  ROUTE_EDUCATIONAL,
  ROUTE_FECAL,
  ROUTE_PATIENTS_GOALS,
  ROUTE_PATIENT_DETAIL,
  ROUTE_QUESTIONNAIRES,
  ROUTE_STATISTICS,
  ROUTE_SYMPTOM,
  ROUTE_URINATION,
} from '../../routes/routes-constants'
import style from './PatientData.module.css'
import objectiveIcon from '../../assets/higea/ico-objetivos-num copy.svg'
import objectiveIconWhite from '../../assets/higea/ico-objetivos-copy.svg'
import userIcon from '../../assets/higea/ico-pacientes-num copy.svg'
import educationalIcon from '../../assets/higea/Ico-asignar-nuevo-aprendizaje.svg'
import appointmentIcon from '../../assets/higea/ico-Calendario-visitas.svg'
import questionaryIcon from '../../assets/higea/ico-cuestionario-white.svg'
import styles from '../../Login.module.css'
import { getPatientContainer } from '../../container/patient-module'
import { PatientService } from '../../modules/patients/services/PatientService'
import { PATIENT_SERVICE_KEY } from '../../modules/patients'
import { Patient } from '../../modules/patients/models/Patient'
import { getEducationalContainer } from 'container/educational-module'
import { PatientLessonService } from '../../modules/educational/services/PatientLessonService'
import { EDUCATIONAL_USER_SERVICE_KEY, PATIENTLESSON_SERVICE_KEY } from '../../modules/educational'
import { PatientGoalService } from '../../modules/patients/services/PatientGoalService'
import {
  PATIENT_DIAGNOSTIC_SERVICE_KEY,
  PATIENT_GOAL_SERVICE_KEY,
  PATIENT_POINT_SERVICE_KEY,
  PATIENT_PROBLEM_SERVICE_KEY,
  PATIENT_SYMPTOM_SERVICE_KEY,
  PATIENT_TREATMENT_SERVICE_KEY,
  PROBLEM_SERVICE_KEY,
  TREATMENT_SERVICE_KEY,
} from '../../modules/patients/container'
import { forkJoin, Observable } from 'rxjs'
import { Goal } from '../../modules/goals/models/Goal'
import { getGoalContainer } from 'container/goal-module'
import { GoalService } from 'modules/goals/services/GoalService'
import { GOAL_SERVICE_KEY } from '../../modules/goals/container'
import { getFecalContainer } from '../../container/fecal-module'
import { FecalService } from '../../modules/fecal/services/FecalService'
import { FECAL_SERVICE_KEY } from '../../modules/fecal/container'
import { Fecal } from 'modules/fecal/models/Fecal'
import { Urination } from 'modules/urination/models/Urination'
import { getUrinationContainer } from '../../container/urination-module'
import { UrinationService } from 'modules/urination/services/UrinationService'
import { URINATION_SERVICE_KEY } from 'modules/urination'
import { PatientSymptomService } from '../../modules/patients/services/PatientSymptomService'
import { PatientSymptom } from 'modules/patients/models/PatientSymptom'
import { getQuestionnaireContainer } from '../../container/questionnaire-module'
import { QuestionnairesService } from '../../modules/questionnaires/services/QuestionnaireService'
import { QUESTIONNAIRE_SERVICE_KEY } from 'modules/questionnaires'
import { QuestionnairePatient } from 'modules/questionnaires/models/QuestionnairePatient'
import { Diagnostic } from 'modules/patients/models/Diagnostic'
import { PatientDiagnosticService } from '../../modules/patients/services/PatientDiagnosticService'
import { Treatment } from '../../modules/patients/models/Treatment'
import { TreatmentService } from '../../modules/patients/services/TreatmentService'
import { FaLocationArrow } from 'react-icons/fa'
import { getMessengerContainer } from '../../container/messenger-module'
import { ConversationService } from '../../modules/messenger/services/ConversationService'
import { CONVERSATION_SERVICE_KEY } from '../../modules/messenger'
import {
  ConversationDTO,
  emptyConversationDTO,
  toModel,
} from 'modules/messenger/models/ConversationDTO'
import { Query, QueryParam } from 'common/api/Query'
import { ConversationQuery } from 'modules/messenger/models/Conversation'
import { v4 as uuidv4 } from 'uuid'
import { Message } from 'modules/messenger/models/Message'
import {
  NotificationMessage,
  NotificationMessageQuery,
} from 'modules/messenger/models/NotificationMessage'
import { getUserContainer } from '../../container/user-module'
import { ILoggedUserService } from '../../modules/users/services/LoggedUserService'
import { IUserService, LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users'
import statisticsIcon from '../../assets/higea/Ico-ver-informes.svg'
import { FileService } from '../../modules/files/services/FileService'
import { FILE_SERVICE_KEY } from 'modules/files'
import { getFileContainer } from 'container/file-module'
import { File } from '../../modules/files/models/File'
import { FileDTO } from 'modules/files/models/FileDTO'
import { PatientPointService } from 'modules/patients/services/PatientPointService'
import starOnIcon from '../../assets/higea/Ico_estrella.svg'
import starOffIcon from '../../assets/higea/Ico_estrella-Copy-2.svg'
import { User } from '../../modules/users/models/User'
import { PatientProblemService } from 'modules/patients/services/PatientProblemService'
import { ProblemService } from 'modules/patients/services/ProblemService'
import { PatientTreatmentService } from 'modules/patients/services/PatientTreatmentService'
import { PatientTreatmentQuery } from 'modules/patients/models/PatientTreatment'
import { EducationalUserService } from 'modules/educational/services/EducationalUserService'
import { NotificationMessageService } from '../../modules/messenger/services/NotificationMessageService'
import { NOTIFICATION_MESSAGE_SERVICE_KEY } from '../../modules/messenger'
import { fromModel } from '../../modules/messenger/models/NotificationMessageDTO'
import { useGlobalContext } from '../../common/utils/MyGlobalContext'
import iconNotifOff from '../../assets/higea/bell-off.svg'
import iconNotifOn from '../../assets/higea/bell-hover.svg'

type PatientDataProps = {
  id: string
}

enum StatusLesson {
  Pending = 1,
  Started = 2,
  Finished = 3,
}

interface StatusProp {
  statusL: StatusLesson
}

interface EducationalMaterialInterface {
  id: string
  title: string
  completed: boolean
}

interface TreatmentInterface {
  name: string
  brand: string
}

const patientPointsService =
  getPatientContainer().get<PatientPointService>(PATIENT_POINT_SERVICE_KEY)
const problemService = getPatientContainer().get<ProblemService>(PROBLEM_SERVICE_KEY)
const patientProblemService = getPatientContainer().get<PatientProblemService>(
  PATIENT_PROBLEM_SERVICE_KEY
)
const userService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)
const patientService = getPatientContainer().get<PatientService>(PATIENT_SERVICE_KEY)
const patientGoalService = getPatientContainer().get<PatientGoalService>(PATIENT_GOAL_SERVICE_KEY)
const goalService = getGoalContainer().get<GoalService>(GOAL_SERVICE_KEY)
const fecalService = getFecalContainer().get<FecalService>(FECAL_SERVICE_KEY)
const urinationService = getUrinationContainer().get<UrinationService>(URINATION_SERVICE_KEY)
const patientSymptomsService = getPatientContainer().get<PatientSymptomService>(
  PATIENT_SYMPTOM_SERVICE_KEY
)
const questionnaireService =
  getQuestionnaireContainer().get<QuestionnairesService>(QUESTIONNAIRE_SERVICE_KEY)
const patientDiagnosticService = getPatientContainer().get<PatientDiagnosticService>(
  PATIENT_DIAGNOSTIC_SERVICE_KEY
)
const treatmentService = getPatientContainer().get<TreatmentService>(TREATMENT_SERVICE_KEY)
const conversationsService =
  getMessengerContainer().get<ConversationService>(CONVERSATION_SERVICE_KEY)
const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const fileContainer = getFileContainer()
const fileService = fileContainer.get<FileService>(FILE_SERVICE_KEY)
const patientTreatmentService = getPatientContainer().get<PatientTreatmentService>(
  PATIENT_TREATMENT_SERVICE_KEY
)
const educationalUserService = getEducationalContainer().get<EducationalUserService>(
  EDUCATIONAL_USER_SERVICE_KEY
)

const notificationMessagesServices = getMessengerContainer().get<NotificationMessageService>(
  NOTIFICATION_MESSAGE_SERVICE_KEY
)

export function PatientData(props: PatientDataProps) {
  const { t } = useTranslation()
  const [user, setUser] = useState<User>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [assignedGoal, setAssignedGoal] = useState<Goal[]>([])
  const [lastFecal, setLastFecal] = useState<Fecal>()
  const [lastUrination, setLastUrination] = useState<Urination>()
  const [lastSymptom, setLastSymptom] = useState<PatientSymptom>()
  const [lastQuestionnaire, setLastQuestionnaire] = useState<QuestionnairePatient>()
  const [diagnosis, setDiagnosis] = useState<Diagnostic>()
  const [treatments, setTreatments] = useState<TreatmentInterface[]>([])
  const [patient, setPatient] = useState<Patient>()
  const handlerAddEducational = () => navigate(ROUTE_EDUCATIONAL.replace(':id', `${props.id}`))
  const [conversation, setConversation] = useState<ConversationDTO>()
  const [messages, setMessages] = useState<Message[]>([])
  const [message, setMessage] = useState<string>('')
  const [socket, setWebsocket] = useState<WebSocket>()
  const [loadingChat, setLoadingChat] = useState<boolean>(true)
  const [scrollEl, setScrollEl] = useState<any>()
  const [profileImage, setProfileImage] = useState<FileDTO>()
  const [stars, setStars] = useState<number>(0)
  const [problems, setProblems] = useState<string>('')
  const [educaitonalInters, setEducationalInters] = useState<EducationalMaterialInterface[]>([])
  const { innerWidth } = window
  const { messagesHeader, setMessagesHeader } = useGlobalContext()
  const { messagesForMe, setMessagesForMe } = useGlobalContext()
  const { allMessages, setAllMessages } = useGlobalContext()
  const { bellNotif, setBellNotif } = useGlobalContext()
  const loggedUser = loggedUserService.get()

  useEffect(() => {
    notificationMessagesServices
      .getFilteredList(
        new Query({
          query: [
            new QueryParam<NotificationMessageQuery>(
              'recipientID',
              loggedUserService?.get()?.id || ''
            ),
            new QueryParam<NotificationMessageQuery>('messageOfPatientID', props.id),
          ],
        })
      )
      .subscribe((res) => {
        let notReaden = res.items.filter((i) => i.readed == false)
        notReaden.forEach((nMessage) => {
          nMessage.readed = true
          notificationMessagesServices.update(fromModel(nMessage)).subscribe(() => {
            notificationMessagesServices
              .getFilteredList(
                new Query({
                  query: [new QueryParam('recipientID', loggedUser?.id || '')],
                })
              )
              .subscribe((res) => {
                setAllMessages(res.items.filter((i) => i.readed == false))
                const ids = res.items
                  .filter((i) => i.messageOfID != loggedUser?.id && i.readed == false)
                  .map((r) => r.messageOfID)

                var o: any = {}
                var len = ids.length
                for (let i = 0; i < len; i++) {
                  o[ids[i]] = (o[ids[i]] || 0) + 1
                }

                var sortable = []
                for (let name in o) {
                  let n = res.items.filter((i) => i.messageOfID == name)[0]
                  sortable.push({
                    name: n.messageOfName,
                    count: o[name],
                    messageOfID: n.messageOfID,
                    messageOfPatientID: n.messageOfPatientID,
                  })
                }

                setMessagesHeader(sortable)

                let messagesForMeAux = res.items.filter(
                  (i) => i.messageOfID == loggedUser?.id && i.readed == false
                )

                setMessagesForMe(messagesForMeAux)

                setBellNotif(
                  sortable.length > 0 || messagesForMeAux.length > 0 ? iconNotifOn : iconNotifOff
                )
              })
          })
        })
      })
  }, [])

  function reduceString(text: string, textLimit: number) {
    const finalChars = '...'

    return text.length > textLimit
      ? text.slice(0, textLimit - 1 - finalChars.length).concat(finalChars)
      : text
  }

  useEffect(() => {
    if (patient) {
      patientProblemService
        .getFilteredItems(
          new Query({
            query: [
              {
                name: 'patientID',
                value: patient?.id || '',
              },
            ],
          })
        )
        .subscribe((res) => {
          if (res.count > 0) {
            let ids = res.items.map((i) => i.problemID)
            problemService.getFilteredList(new Query({})).subscribe((res) => {
              let problems = ''
              res.items
                .filter((i) => ids.includes(i.id))
                .forEach((e) => {
                  if (problems == '') {
                    problems = problems + t(e.name)
                  } else {
                    problems = problems + ', ' + t(e.name)
                  }
                })
              setProblems(problems)
            })
          }
        })
    }
  }, [patient])

  useEffect(() => {
    if (patient) {
      userService.getByID(patient?.userID || '').subscribe((res) => {
        setUser(res)
      })
    }
  }, [patient])

  useEffect(() => {
    if (user) {
      fileService
        .getFilteredItems(
          new Query({
            query: [
              new QueryParam<File>('ownerID', user?.id || ''),
              new QueryParam<File>('name', 'profileImage'),
            ],
          })
        )
        .subscribe((res) => {
          if (res.items.length > 0) {
            setProfileImage(res.items[0])
          }
        })
    }
  }, [user])

  useEffect(() => {
    if (scrollEl) {
      scrollEl.scrollTop = scrollEl.scrollHeight
    }
  }, [scrollEl])

  useEffect(() => {
    if (socket) {
      connect()
    }
  }, [socket])

  useEffect(() => {
    if (conversation?.id) {
      let userID = loggedUserService?.get()?.id || ''
      let from = process.env.REACT_APP_BACKEND_URL?.lastIndexOf('/') || 0
      let url = process.env.REACT_APP_BACKEND_URL?.substring(
        from + 1,
        process.env.REACT_APP_BACKEND_URL?.length
      )
      setWebsocket(
        new WebSocket(
          `wss://backend-higea.visualtis.com/ws?conversationID=${conversation?.id}&senderID=${userID}`
        )
      )
    }
  }, [conversation])

  let connect = () => {
    if (socket) {
      socket.onopen = () => {
        console.log('Successfully Connected')
        setLoadingChat(false)
      }
    }

    if (socket) {
      socket.onmessage = (msg) => {
        var m = JSON.parse(msg.data)
        setIsLoading(true)
        const auxMessages: Message[] = messages
        auxMessages.push(
          new Message({
            id: uuidv4(),
            text: m.message,
            authorID: m.senderID,
            conversationID: m.conversationID,
            createdAt: new Date(),
            senderID: loggedUserService?.get()?.id || '',
            status: 0,
            recipientID: '',
            recipientName: '',
          })
        )
        setMessages(auxMessages)
        setIsLoading(false)
      }
    }

    if (socket) {
      socket.onclose = (event) => {
        console.log('Socket Closed Connection: ')
      }
    }

    if (socket) {
      socket.onerror = (error) => {
        console.log('Socket Error: ', error)
      }
    }
  }

  const sendMessage = () => {
    if (message != '') {
      const m2 = new Message({
        id: uuidv4(),
        text: message,
        authorID: loggedUserService.get()?.id || '',
        conversationID: conversation?.id || '',
        createdAt: new Date(),
        senderID: loggedUserService?.get()?.id || '',
        status: 0,
        recipientID: patient?.userID || '',
        recipientName: patient?.firstName || '',
      })

      if (socket) {
        socket.send(
          JSON.stringify({
            message: message,
            conversationID: conversation?.id,
            senderID: loggedUserService?.get()?.id || '',
            authorID: loggedUserService.get()?.id || '',
          })
        )
      }

      conversationsService.addMessage(m2).subscribe((res) => {
        const auxMessages: Message[] = messages
        auxMessages.push(m2)
        setMessages(auxMessages)
        setMessage('')
        scrollTo()
      })
    }

    let notMessage = new NotificationMessage({
      id: uuidv4(),
      recipientID: patient?.createdBy || '',
      messageOfID: patient?.userID || '',
      messageOfPatientID: patient?.id || '',
      messageOfName: patient?.firstName || '',
      readed: false,
    })

    notificationMessagesServices.add(fromModel(notMessage)).subscribe((res) => {
      notificationMessagesServices
        .getFilteredList(
          new Query({
            query: [new QueryParam('recipientID', loggedUser?.id || '')],
          })
        )
        .subscribe((res) => {
          setAllMessages(res.items.filter((i) => i.readed == false))
          const ids = res.items
            .filter((i) => i.messageOfID != loggedUser?.id && i.readed == false)
            .map((r) => r.messageOfID)

          var o: any = {}
          var len = ids.length
          for (let i = 0; i < len; i++) {
            o[ids[i]] = (o[ids[i]] || 0) + 1
          }

          var sortable = []
          for (let name in o) {
            let n = res.items.filter((i) => i.messageOfID == name)[0]
            sortable.push({
              name: n.messageOfName,
              count: o[name],
              messageOfID: n.messageOfID,
              messageOfPatientID: n.messageOfPatientID,
            })
          }

          setMessagesHeader(sortable)

          let messagesForMeAux = res.items.filter(
            (i) => i.messageOfID == loggedUser?.id && i.readed == false
          )

          setMessagesForMe(messagesForMeAux)

          setBellNotif(
            sortable.length > 0 || messagesForMeAux.length > 0 ? iconNotifOn : iconNotifOff
          )
        })
    })
  }

  useEffect(() => {
    connect()
  }, [])

  const scrollTo = () => {
    if (scrollEl) {
      scrollEl.scrollTop = scrollEl.scrollHeight
    }
  }

  useEffect(() => {
    if (patient?.userID) {
      conversationsService
        .getFilteredItems(
          new Query({
            query: [new QueryParam<ConversationQuery>('user', patient?.userID || '')],
          })
        )
        .subscribe((res) => {
          if (res.length > 0) {
            setConversation(res[0])
            setMessages(res[0]?.messages)
            scrollTo()
          } else {
            let emptyConver = emptyConversationDTO(
              patient?.userID || '',
              loggedUserService.get()?.id || ''
            )
            conversationsService.add(toModel(emptyConver)).subscribe(() => {
              setConversation(emptyConver)
            })
          }
        })
    }

    conversationsService.setAllPatientMessagesReaded(patient?.userID || '').subscribe((res) => {
      return res
    })

    setIsLoading(false)
  }, [patient])

  useEffect(() => {
    if (user) {
      getEducationalAssigned()
    }
  }, [user])

  useEffect(() => {
    patientService.getByID(props.id || '').subscribe((res) => {
      setPatient(res)
    })
    getGoals()
    getLastDocuments()
    getPatientData()
  }, [])

  const handleAppointment = () => {
    navigate(ROUTE_APPOINTMENTS.replace(':id', props.id)).then()
  }

  const handleAssignObjective = () => {
    navigate(ROUTE_PATIENTS_GOALS.replace(':id', props.id)).then()
  }

  const goToSymptom = () => {
    navigate(ROUTE_SYMPTOM.replace(':id', props.id)).then()
  }
  const handleStatistics = () => {
    navigate(ROUTE_STATISTICS.replace(':id', props.id)).then()
  }

  const handleFecal = () => {
    navigate(ROUTE_FECAL.replace(':id', props.id)).then()
  }

  const handleUrination = () => {
    navigate(ROUTE_URINATION.replace(':id', props.id)).then()
  }

  const handleViewQuestionnaires = () => {
    navigate(ROUTE_QUESTIONNAIRES.replace(':id', props.id)).then()
  }

  const patientLessonService =
    getEducationalContainer().get<PatientLessonService>(PATIENTLESSON_SERVICE_KEY)

  const getAge = () => {
    var today = new Date()
    var dob = new Date(patient?.dob || '')
    var age = today.getFullYear() - dob.getFullYear()
    var m = today.getMonth() - dob.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < dob.getDate())) {
      age--
    }
    return age
  }

  const getEducationalAssigned = () => {
    educationalUserService
      .getFilteredItems(new Query({ query: [new QueryParam('userID', user?.id || '')] }))
      .subscribe((educationalUser) => {
        let educationalInterAux: EducationalMaterialInterface[] = []
        let educationalUserEdIds = educationalUser.items.map((i) => i.educationalID)
        patientLessonService
          .getLessonsAndModulesAssignedByIDPatient(props.id || '')
          .subscribe((res) => {
            res.items.slice(0, 3).forEach((i) => {
              let completed = false
              if (educationalUserEdIds.includes(i.id)) {
                completed = true
              }
              educationalInterAux.push({
                id: i.id,
                title: i.name,
                completed: completed,
              })
            })
            setEducationalInters(educationalInterAux)
          })
      })
  }

  useEffect(() => {
    patient &&
      patientPointsService.getPatientPointsByPatientID(patient?.id || '').subscribe((res2) => {
        let pt = 0
        res2.items.forEach((i) => {
          pt += i.points
        })
        setStars(patientPointsService.getLevelFromPoints(pt))
      })
  }, [patient])

  const getAssignedGoals = (ids: string[]): Observable<(Goal | undefined)[]> =>
    forkJoin(ids.map((id) => goalService.getByID(id)))

  const getGoals = () => {
    patientGoalService.getListByID(props.id).subscribe((res) => {
      getAssignedGoals(res.items.map((n) => n.goalID)).subscribe((ag) => {
        ag.forEach((g, i) => {
          g && setAssignedGoal((assignedGoal) => [...assignedGoal, g])
        })
      })
    })
  }

  const getPatientData = () => {
    patientDiagnosticService.getPatientDiagnostic(props.id).subscribe((res) => {
      res && setDiagnosis(res)
    })

    patientTreatmentService
      .getFilteredItems(
        new Query({
          query: [new QueryParam<PatientTreatmentQuery>('patientID', props.id)],
        })
      )
      .subscribe((res1) => {
        let res1Filtered = res1.items.filter((i) => i.patientID == props.id)
        let ids = res1Filtered.map((i) => i.treatmentID)
        let auxTreatments: TreatmentInterface[] = []
        treatmentService.getFilteredList(new Query({})).subscribe((res) => {
          let treatments = res.items.filter((i) => ids.includes(i.id))
          treatments.forEach((t) => {
            auxTreatments.push({
              name: t.name,
              brand: res1Filtered.filter((i) => i.treatmentID == t.id)[0].brand,
            })
          })
          setTreatments(auxTreatments)
        })
      })
  }

  const getLastDocuments = () => {
    //fecal
    fecalService.getLastDocument(props.id || '').subscribe((res) => {
      res && setLastFecal(res)
    })

    //miccional
    urinationService.getLastDocument(props.id || '').subscribe((resU) => {
      resU && setLastUrination(resU)
    })

    //Symptom
    patientSymptomsService.getLastDocument(props.id || '').subscribe((resS) => {
      resS && setLastSymptom(resS)
    })

    questionnaireService.getLastDocument(props.id || '').subscribe((resQ) => {
      resQ && setLastQuestionnaire(resQ)
    })
  }

  const StatusBar = (props: StatusProp) => {
    if (props.statusL == StatusLesson.Finished) {
      return (
        <>
          <div className={style.progressFinish} />
        </>
      )
    } else if (props.statusL == StatusLesson.Started) {
      return (
        <>
          <div className={style.progresStarted} />
        </>
      )
    } else if (props.statusL == StatusLesson.Pending) {
      return (
        <>
          <div className={style.progressPending} />
        </>
      )
    } else {
      return <></>
    }
  }

  const StatusText = (stateL: StatusLesson) => {
    if (stateL == StatusLesson.Finished) {
      return <div className={style.statusTextCompleted}>{t('completed')}</div>
    } else if (stateL == StatusLesson.Started || stateL == StatusLesson.Pending) {
      return <div className={style.statusTextPending}>{t('pending')}</div>
    } else {
      return ''
    }
  }

  const handlePatientDetail = () => {
    navigate(ROUTE_PATIENT_DETAIL.replace(':id', props.id)).then()
  }

  return (
    <>
      {!isLoading && (
        <Box style={{ marginLeft: '1rem' }}>
          <Grid container spacing={2}>
            <Grid item container spacing={2} columns={8}>
              <Grid className={style.patientElement} item xs={12} md={3}>
                <h3
                  className={style.generalDataH3}
                  style={{
                    color: '#000',
                    fontSize: innerWidth < 900 ? 16 : '',
                    //marginBottom: innerWidth < 900 ? '9%' : '',
                  }}
                >
                  {t('generalData')}
                </h3>
                <Box className={style.gridPatient}>
                  <Box className={style.patientAvatar}>
                    {innerWidth < 900 ? (
                      <Box className={style.boxPatientDetail}>
                        {profileImage && profileImage.data.length > 0 ? (
                          <Avatar
                            src={`data:image/jpeg;base64,${profileImage.data}`}
                            alt="userlogo"
                            className={styles.logo}
                            style={{ marginTop: '10%' }}
                            sx={{ width: 80, height: 80 }}
                          />
                        ) : (
                          <Avatar
                            src={userIcon}
                            alt="userlogo"
                            className={styles.logo}
                            style={{ marginTop: '10%' }}
                            sx={{ width: 80, height: 80 }}
                          />
                        )}
                        <div className={style.patientDetailTablet} onClick={handlePatientDetail}>
                          {t('details')}
                        </div>
                      </Box>
                    ) : (
                      <Box className={style.boxPatientDetail}>
                        {profileImage && profileImage.data.length > 0 ? (
                          <Avatar
                            src={`data:image/jpeg;base64,${profileImage.data}`}
                            alt="userlogo"
                            className={styles.logo}
                            style={{ marginTop: '10%' }}
                            sx={{ width: 80, height: 80 }}
                          />
                        ) : (
                          <Avatar
                            src={userIcon}
                            alt="userlogo"
                            className={styles.logo}
                            style={{ marginTop: '10%' }}
                            sx={{ width: 80, height: 80 }}
                          />
                        )}
                        <div className={style.patientDetail} onClick={handlePatientDetail}>
                          {t('details')}
                        </div>
                      </Box>
                    )}
                    <Divider orientation="vertical" variant="middle" flexItem />
                  </Box>
                  <Box
                    className={style.patientData}
                    /*style={
                      innerWidth < 900
                        ? { marginLeft: '24.7%', fontSize: 11.5, overflow: 'hidden' }
                        : { fontSize: 14.8 }
                    }*/
                  >
                    {/*<div>
                      Núm ID: {patient?.id} {patient?.id}
                    </div>*/}
                    <div>
                      {patient?.firstName} {patient?.lastName}
                    </div>
                    <div>Edad: {getAge()} años</div>
                    <div>Telf: {patient?.contactPhone}</div>
                    {innerWidth < 900 ? (
                      <>
                        <div>e-mail: </div>
                        <div>{reduceString(patient?.email || '', 20)}</div>
                      </>
                    ) : (
                      <>
                        <div>e-mail: {patient?.email || ''}</div>
                      </>
                    )}

                    <br />
                    <div>
                      {t('diagnosis')}: {diagnosis?.name}
                    </div>
                    <div>
                      {t('problem')}: {problems}
                    </div>
                    <div style={{ marginRight: '1%' }}>{t('statusRank')}:</div>
                    <Box display={'flex'} flexDirection={'row'}>
                      {(function () {
                        let starsIcons = []
                        for (var i = 1; i <= stars; i++) {
                          starsIcons.push(
                            <img
                              src={starOnIcon}
                              style={{
                                width: innerWidth < 900 ? '18px' : '20px',
                                marginTop: innerWidth < 900 ? '1%' : '0%',
                              }}
                              alt="starOn"
                            />
                          )
                        }
                        for (var i = 1; i <= 5 - stars; i++) {
                          starsIcons.push(
                            <img
                              src={starOffIcon}
                              style={{
                                width: innerWidth < 900 ? '18px' : '20px',
                                marginTop: innerWidth < 900 ? '1%' : '0%',
                              }}
                              alt="starOff"
                            />
                          )
                        }

                        return starsIcons
                      })()}
                    </Box>
                    <br />
                    <div style={{ fontWeight: 'bold' }}>{t('currentTreatment')}</div>
                    {treatments.map((treatment) => {
                      return (
                        <>
                          <div>
                            {treatment?.name}, {t('brand')}: {treatment?.brand}
                          </div>
                        </>
                      )
                    })}
                    <div>
                      {t('diet')}: {patient?.diet}
                    </div>
                    <div>
                      {t('pharmaceuticals')}: {patient?.medicine}
                    </div>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} sm={5} md={3}>
                <Grid item xs={4}>
                  <h3
                    className={style.generalDataH3}
                    style={{ fontSize: innerWidth < 900 ? 16 : '' }}
                  >
                    {t('objectives')}
                  </h3>
                </Grid>
                <Box className={style.gridPatientO}>
                  <Box className={style.goalsContainer}>
                    {assignedGoal
                      .reverse()
                      .slice(0, 4)
                      .map((elem) => {
                        return (
                          <>
                            <Box
                              className={innerWidth < 900 ? style.goalElemTablet : style.goalElem}
                            >
                              {innerWidth < 900 ? (
                                <img
                                  src={objectiveIcon}
                                  alt="objective"
                                  style={{ marginRight: 25 }}
                                  width={50}
                                  height={50}
                                />
                              ) : (
                                <img
                                  src={objectiveIcon}
                                  alt="objective"
                                  style={{ marginRight: 25 }}
                                />
                              )}
                              <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                <h4 style={{ margin: 0, color: '#000' }}>{elem.parentTitle}</h4>
                                <Typography
                                  style={{ color: '#000', fontFamily: 'Open-sans, sans-serif' }}
                                >
                                  {elem.title}
                                </Typography>
                              </Box>
                            </Box>
                          </>
                        )
                      })}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={3} md={2} className={style.gridMessaging}>
                <h3
                  style={{
                    color: '#000',
                    fontSize: innerWidth < 900 ? 16 : '',
                    //marginBottom: innerWidth < 900 ? '9%' : '',
                  }}
                >
                  {t('messaging')}
                </h3>
                <Box className={style.gridPatient2} style={{ height: 315 }}>
                  {!loadingChat && (
                    <>
                      <Box
                        id="scrollBox"
                        ref={(ref) => {
                          setScrollEl(ref)
                        }}
                        className={style.patientBox}
                        style={{
                          height: '83%',
                          overflowY: 'auto',
                          display: 'flex',
                          flexDirection: 'column',
                          paddingLeft: '20px',
                          paddingRight: '20px',
                        }}
                      >
                        {messages.map((item, i) => (
                          <>
                            {item.authorID != loggedUserService.get()?.id ? (
                              <Box
                                className={style.patientBox2}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                }}
                              >
                                <Box
                                  className={style.patientBox3}
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  {profileImage && profileImage.data.length > 0 ? (
                                    <Avatar
                                      src={`data:image/jpeg;base64,${profileImage.data}`}
                                      className={styles.logo}
                                      sx={{ width: 38 }}
                                      style={{ marginTop: 25, marginRight: 8 }}
                                      alt="chatUserLogo"
                                    />
                                  ) : (
                                    <img
                                      src={
                                        require('../../assets/higea/ico-pacientes-num copy.svg')
                                          .default
                                      }
                                      style={{ marginTop: 25, width: 38, marginRight: 8 }}
                                      alt="chatUserLogo"
                                    />
                                  )}
                                </Box>
                                <Box
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    paddingTop: 15,
                                  }}
                                >
                                  <Box
                                    style={{
                                      fontSize: 10,
                                      marginBottom: '1%',
                                      color: 'grey',
                                      fontFamily: 'Open-sans, sans-serif',
                                    }}
                                  >
                                    {patient?.firstName} {patient?.lastName}
                                  </Box>
                                  <Box
                                    style={{
                                      backgroundColor: '#d6ddf1',
                                      padding: '14px 25px 14px 14px',
                                      lineBreak: 'anywhere',
                                      fontSize: 13,
                                      textAlign: 'left',
                                      borderRadius: 20,
                                      fontFamily: 'Open-sans, sans-serif',
                                    }}
                                  >
                                    {item.text}
                                  </Box>
                                </Box>
                              </Box>
                            ) : (
                              <Box
                                style={{
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  paddingTop: 15,
                                }}
                              >
                                <Box
                                  style={{ width: window.innerWidth > 1490 ? '70px' : '121px' }}
                                />
                                <Box
                                  style={{
                                    backgroundColor: '#2b55b4',
                                    lineBreak: 'anywhere',
                                    color: 'white',
                                    padding: '14px 25px 14px 14px',
                                    fontSize: 13,
                                    textAlign: 'left',
                                    borderRadius: 20,
                                    fontFamily: 'Open-sans, sans-serif',
                                  }}
                                >
                                  {item.text}
                                </Box>
                              </Box>
                            )}
                          </>
                        ))}
                      </Box>
                    </>
                  )}
                </Box>
                <Box
                  className={style.boxMessage}
                  style={{
                    backgroundColor: 'white',
                    padding: '20px 30px',
                    borderRadius: '12px',
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '1%',
                  }}
                >
                  <Box
                    style={{
                      display: 'flex',
                      backgroundColor: '#d6eaff',
                      borderRadius: 58,
                      padding: 12,
                      width: '100%',
                      height: 45,
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <TextField
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            sendMessage()
                          }
                        }}
                        //label={t('enterTextChat')}
                        value={message}
                        variant="standard"
                        style={{
                          width: '100%',
                        }}
                        InputProps={{
                          disableUnderline: true,
                          style: { fontFamily: 'Open-sans, sans-serif' },
                        }}
                        placeholder={t('enterTextChat')}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </Box>
                    {/*<FaLocationArrow
                        size={23}
                        color="#683b8a"
                        style={{ marginRight: '2%' }}
                        onClick={sendMessage}
                    />*/}
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid
              item
              container
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              spacing={2}
              style={{ marginTop: '20px', marginBottom: '20px' }}
            >
              <Grid item xs={2.3}>
                <Box
                  style={{ backgroundColor: '#2b55b4', color: 'white', fontWeight: 'bold' }}
                  className={innerWidth < 900 ? style.gridButtonTablet2 : style.gridButton2}
                  onClick={handlerAddEducational}
                >
                  {innerWidth < 900 ? (
                    <img
                      src={educationalIcon}
                      alt="reports"
                      style={{ marginRight: 10 }}
                      width={40}
                      height={40}
                    />
                  ) : (
                    <img
                      src={educationalIcon}
                      alt="reports"
                      style={{ marginRight: 10 }}
                      width={50}
                      height={50}
                    />
                  )}
                  {t('assingNewLearning')}
                </Box>
              </Grid>

              <Grid item xs={2.3} style={{ width: '25%' }}>
                <Box
                  style={{ backgroundColor: '#6d1ea9', color: 'white', fontWeight: 'bold' }}
                  className={innerWidth < 900 ? style.gridButtonTablet : style.gridButton2}
                  onClick={handleAssignObjective}
                >
                  {innerWidth < 1025 ? (
                    <img
                      src={objectiveIconWhite}
                      alt="objective"
                      style={{ marginRight: 10 }}
                      width={40}
                      height={40}
                    />
                  ) : (
                    <img
                      src={objectiveIconWhite}
                      alt="objective"
                      style={{ marginRight: 10 }}
                      width={50}
                      height={50}
                    />
                  )}
                  {t('patientsAddGoals')}
                </Box>
              </Grid>
              <Grid item xs={2.3}>
                <Box
                  style={{ backgroundColor: '#58b03a', color: 'white', fontWeight: 'bold' }}
                  className={style.gridButton}
                  mt={0}
                  onClick={handleViewQuestionnaires}
                >
                  {innerWidth < 1025 ? (
                    <img
                      src={questionaryIcon}
                      alt="questionary"
                      style={{ marginRight: 10 }}
                      width={40}
                      height={40}
                    />
                  ) : (
                    <img
                      src={questionaryIcon}
                      alt="questionary"
                      style={{ marginRight: 10 }}
                      width={50}
                      height={50}
                    />
                  )}
                  {t('asignQuestionnaire')}
                </Box>
              </Grid>
              <Grid item xs={2.3}>
                <Box
                  style={{ backgroundColor: '#ff8204', color: 'white', fontWeight: 'bold' }}
                  className={innerWidth < 900 ? style.gridButtonTablet3 : style.gridButton}
                  onClick={handleStatistics}
                >
                  {innerWidth < 1025 ? (
                    <img
                      src={statisticsIcon}
                      alt="statisticsIcon"
                      style={{ marginRight: 10 }}
                      width={40}
                      height={40}
                    />
                  ) : (
                    <img
                      src={statisticsIcon}
                      alt="statisticsIcon"
                      style={{ marginRight: 10 }}
                      width={50}
                      height={50}
                    />
                  )}
                  {t('showInforms')}
                </Box>
              </Grid>
              <Grid item xs={2.3}>
                <Box
                  style={{ backgroundColor: '#f0007e', color: 'white', fontWeight: 'bold' }}
                  className={style.gridButton}
                  mt={0}
                  onClick={handleAppointment}
                >
                  {innerWidth < 1025 ? (
                    <img
                      src={appointmentIcon}
                      alt="appointment"
                      style={{ marginRight: 10 }}
                      width={40}
                      height={40}
                    />
                  ) : (
                    <img
                      src={appointmentIcon}
                      alt="appointment"
                      style={{ marginRight: 10 }}
                      width={50}
                      height={50}
                    />
                  )}
                  {t('visitCalendar')}
                </Box>
              </Grid>
            </Grid>
            <Grid item container display={'flex'} alignItems={'center'} spacing={2}>
              <Grid item xs={12} md={4.5}>
                <Grid
                  container
                  spacing={4}
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Grid item>
                    <h3
                      className={style.generalDataH3}
                      style={{ fontSize: innerWidth < 900 ? 16 : '' }}
                    >
                      {t('educationMaterial')}
                    </h3>
                  </Grid>
                </Grid>
                <Box className={style.gridPatientO} style={{ margin: 0 }}>
                  <Box
                    className={
                      innerWidth < 900 ? style.contentEducationalTablet : style.contentEducational
                    }
                  >
                    {educaitonalInters.map((elem) => {
                      return (
                        <>
                          <Box className={style.educationalElement}>
                            <Box className={style.educationalBar}>
                              <h4 style={{ color: '#000' }}>{elem.title}</h4>
                              <StatusBar
                                statusL={
                                  elem.completed ? StatusLesson.Finished : StatusLesson.Pending
                                }
                              />
                            </Box>
                            <Box
                              className={
                                innerWidth < 900
                                  ? style.statusEducationalTablet
                                  : style.statusEducational
                              }
                            >
                              {StatusText(
                                elem.completed ? StatusLesson.Finished : StatusLesson.Pending
                              )}
                            </Box>
                          </Box>
                        </>
                      )
                    })}
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} md={7.5}>
                <h3
                  className={style.generalDataH3}
                  style={{
                    color: '#000',
                    fontSize: innerWidth < 900 ? 16 : '',
                    //marginBottom: innerWidth < 900 ? '14%' : '',
                  }}
                >
                  {t('patientMonitoring')}
                </h3>
                <Box className={style.gridPatient} style={{ overflow: 'hidden' }}>
                  <Box className={style.documentsContainer}>
                    <Box className={style.documents}>
                      <Box className={style.documentsSeparator}>
                        {loggedUserService.get()?.language == 1 ? (
                          <h5 style={{ color: '#000' }}>
                            {lastFecal &&
                              new Date(lastFecal.date).getDate() +
                                '/' +
                                (new Date(lastFecal.date).getMonth() + 1) +
                                '/' +
                                new Date(lastFecal.date).getFullYear().toString().substring(2, 4)}
                          </h5>
                        ) : (
                          <h5 style={{ color: '#000' }}>
                            {lastFecal && new Date(lastFecal.date).toLocaleDateString()}
                          </h5>
                        )}
                      </Box>
                      <Box display={'flex'} flexDirection={'column'}>
                        <h4 style={{ color: '#000' }}>{t('fecalMonitoring')}</h4>
                        <Button
                          className={style.documentsButton}
                          type={'button'}
                          style={innerWidth < 900 ? { width: '80px' } : {}}
                          variant="contained"
                          size={'small'}
                          onClick={handleFecal}
                        >
                          <div
                            style={{
                              fontSize: innerWidth < 900 ? 10 : 12,
                              fontWeight: 'bold',
                              fontFamily: 'Open-sans, sans-serif',
                            }}
                          >
                            {t('access')}
                          </div>
                        </Button>
                      </Box>
                    </Box>
                    <Box className={style.documents}>
                      <Box
                        className={style.documentsSeparator}
                        /*style={innerWidth < 900 ? { marginLeft: '-1%' } : {}}*/
                      >
                        {loggedUserService.get()?.language == 1 ? (
                          <h5 style={{ color: '#000' }}>
                            {lastUrination &&
                              new Date(lastUrination.date).getDate() +
                                '/' +
                                (new Date(lastUrination.date).getMonth() + 1) +
                                '/' +
                                new Date(lastUrination.date)
                                  .getFullYear()
                                  .toString()
                                  .substring(2, 4)}
                          </h5>
                        ) : (
                          <h5 style={{ color: '#000' }}>
                            {lastUrination && new Date(lastUrination.date).toLocaleDateString()}
                          </h5>
                        )}
                      </Box>
                      <Box display={'flex'} flexDirection={'column'}>
                        <h4 style={{ color: '#000' }}>{t('urinationMonitoring')}</h4>
                        <Button
                          className={style.documentsButton}
                          type={'button'}
                          variant="contained"
                          size={'small'}
                          onClick={handleUrination}
                        >
                          <div
                            style={{
                              fontSize: innerWidth < 900 ? 10 : 12,
                              fontWeight: 'bold',
                              fontFamily: 'Open-sans, sans-serif',
                            }}
                          >
                            {t('access')}
                          </div>
                        </Button>
                      </Box>
                    </Box>

                    <Box className={style.documents}>
                      <Box
                        className={style.documentsSeparator}
                        /*style={innerWidth < 900 ? { marginLeft: '-7%' } : {}}*/
                      >
                        {loggedUserService.get()?.language == 1 ? (
                          <h5 style={{ color: '#000' }}>
                            {lastSymptom &&
                              new Date(lastSymptom.createdAt).getDate() +
                                '/' +
                                (new Date(lastSymptom.createdAt).getMonth() + 1) +
                                '/' +
                                new Date(lastSymptom.createdAt)
                                  .getFullYear()
                                  .toString()
                                  .substring(2, 4)}
                          </h5>
                        ) : (
                          <h5 style={{ color: '#000' }}>
                            {lastSymptom && new Date(lastSymptom.createdAt).toLocaleDateString()}
                          </h5>
                        )}
                      </Box>
                      <Box display={'flex'} flexDirection={'column'}>
                        <h4 style={{ color: '#000' }}>{t('symptomRecord')}</h4>
                        <Button
                          className={style.documentsButton}
                          type={'button'}
                          variant="contained"
                          style={innerWidth < 900 ? { width: '80px' } : {}}
                          size={'small'}
                          onClick={goToSymptom}
                        >
                          <div
                            style={{
                              fontSize: innerWidth < 900 ? 10 : 12,
                              fontWeight: 'bold',
                              fontFamily: 'Open-sans, sans-serif',
                            }}
                          >
                            {t('access')}
                          </div>
                        </Button>
                      </Box>
                    </Box>

                    {/* <Box className={style.documents}>
                      <Box
                        className={style.documentsSeparator}
                        style={innerWidth < 900 ? { marginLeft: '-7%' } : {}}
                      >
                        {lastQuestionnaire &&
                          new Date(0) < new Date(lastQuestionnaire.finishedAt) && (
                            <>
                              {loggedUserService.get()?.language == 1 ? (
                                <h5 style={{ color: '#000' }}>
                                  {new Date(lastQuestionnaire.finishedAt).getDate() +
                                    '/' +
                                    (new Date(lastQuestionnaire.finishedAt).getMonth() + 1) +
                                    '/' +
                                    new Date(lastQuestionnaire.finishedAt)
                                      .getFullYear()
                                      .toString()
                                      .substring(2, 4)}
                                </h5>
                              ) : (
                                <h5 style={{ color: '#000' }}>
                                  {new Date(lastQuestionnaire.finishedAt).toLocaleDateString()}
                                </h5>
                              )}
                            </>
                          )}
                      </Box>

                      <Box display={'flex'} flexDirection={'column'}>
                        <h4 style={{ color: '#000' }}>{t('questionnaires')}</h4>
                        <Button
                          className={style.documentsButton}
                          type={'button'}
                          variant="contained"
                          style={innerWidth < 900 ? { width: '80px' } : {}}
                          size={'small'}
                          onClick={handleViewQuestionnaires}
                        >
                          <div
                            style={{
                              fontSize: innerWidth < 900 ? 10 : 12,
                              fontWeight: 'bold',
                              fontFamily: 'Open-sans, sans-serif',
                            }}
                          >
                            {t('access')}
                          </div>
                        </Button>
                      </Box>
                    </Box>*/}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  )
}
