import React, { useState, useEffect, useRef } from 'react'
import { Box } from '@material-ui/core'
import style from './Header.module.css'
import { Dropdown } from '../../components/notification-dropdown/Dropdown'
import { navigate } from '@reach/router'
import {
  ROUTE_FAQS,
  ROUTE_GOALS,
  ROUTE_HOME,
  ROUTE_PATIENTS,
  ROUTE_PROFESSIONALS,
  ROUTE_USER_PROFILE,
} from '../../routes/routes-constants'
import { User } from '../../modules/users/models/User'
import { MenuListComposition } from '../../components/popUpMenu/MenuPopUp'
import { getUserContainer } from 'container/user-module'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users'
import iconoWeb from '../../assets/brand_logo/Logo-Higea-header.svg'
import { IUserService } from 'modules/users'
import { useTranslation } from 'react-i18next'
import EmailIcon from '@mui/icons-material/Email'
import { Modal } from '@material-ui/core'
import { TextField } from '@mui/material'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { useSnackbar } from 'notistack'

type HeaderProps = {
  place?: string
  user?: User
}

const userContainer = getUserContainer()
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const userService = userContainer.get<IUserService>(USER_SERVICE_KEY)

export function Header(props: HeaderProps) {
  const { t } = useTranslation()
  const [logout, setLogout] = useState(false)
  const [user, setUser] = useState<User>()
  const [helpModalOpened, setHelpModalOpened] = useState<boolean>(false)
  const [helpText, setHelpText] = useState<string>('')
  const { innerWidth } = window
  const [pathname, setPathname] = useState(window.location.pathname)
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    userService.getByID(loggedUserService.get()?.id || '').subscribe((res) => {
      setUser(res)
    })
  }, [])

  const Navigator = (URL: string) => {
    navigate(URL).then()
    setPathname(window.location.pathname)
  }

  const sendHelpEmail = () => {
    if (helpText != '') {
      userService.sendHelpEmail(helpText, user?.email ? user.email : '')
      setHelpModalOpened(false)
      enqueueSnackbar(t('emailSent'), { variant: 'success' })
    }
  }

  return (
    <>
      <Box className={style.header}>
        <Box display="flex" className={style.left}>
          <img
            src={iconoWeb}
            alt="Logo"
            onClick={() => Navigator(ROUTE_HOME)}
            style={{ width: 75, cursor: 'pointer' }}
          />
        </Box>
        <Box
          display="flex"
          className={style.center}
          style={{ marginLeft: innerWidth < 900 ? '-3%' : '' }}
        >
          {innerWidth < 900 ? (
            <>
              <h5 style={{ color: 'white' }} onClick={() => Navigator(ROUTE_HOME)}>
                {t('start')}
              </h5>
              <h5
                style={{ color: 'white', marginLeft: '5%' }}
                onClick={() => Navigator(ROUTE_PATIENTS)}
              >
                {t('patients')}
              </h5>
              {loggedUserService.isAdmin() && (
                <h5
                  style={{ color: 'white', marginLeft: '5%' }}
                  onClick={() => Navigator(ROUTE_GOALS)}
                >
                  {t('objectives')}
                </h5>
              )}
              {/**
              <h5
                style={{ color: 'white', marginLeft: '5%' }}
                onClick={() => Navigator(ROUTE_EDUCATIONALPROFESSIONAL)}>
                {t('educationalContentHeader')}
              </h5>*/}
              <h5
                style={{ color: 'white', marginLeft: '5%' }}
                onClick={() => Navigator(ROUTE_FAQS)}
              >
                {t('faqs')}
              </h5>
              {loggedUserService.isAdmin() && (
                <h6
                  style={{ color: 'white', marginLeft: '5%' }}
                  onClick={() => Navigator(ROUTE_PROFESSIONALS)}
                >
                  {t('professionals')}
                </h6>
              )}
              <h5
                style={{ color: 'white', marginLeft: '5.5%' }}
                onClick={() => Navigator(ROUTE_USER_PROFILE)}
              >
                {t('myProfile')}
              </h5>
            </>
          ) : (
            <>
              <h6
                style={{
                  color: 'white',
                  fontFamily:
                    pathname === ROUTE_HOME
                      ? "'Open-sans-bold', sans-serif"
                      : "'Open-sans', sans-serif",
                }}
                onClick={() => Navigator(ROUTE_HOME)}
              >
                {t('start')}
              </h6>
              <h6
                style={{
                  color: 'white',
                  fontFamily:
                    pathname === ROUTE_PATIENTS
                      ? "'Open-sans-bold', sans-serif"
                      : "'Open-sans', sans-serif",
                }}
                onClick={() => Navigator(ROUTE_PATIENTS)}
              >
                {t('patients')}
              </h6>
              {loggedUserService.isAdmin() && (
                <h6
                  style={{
                    color: 'white',
                    fontFamily:
                      pathname === ROUTE_GOALS
                        ? "'Open-sans-bold', sans-serif"
                        : "'Open-sans', sans-serif",
                  }}
                  onClick={() => Navigator(ROUTE_GOALS)}
                >
                  {t('objectives')}
                </h6>
              )}
              {/**
              <h6 onClick={() => Navigator(ROUTE_EDUCATIONALPROFESSIONAL)}>
                {t('educationalContentHeader')}
              </h6>*/}
              <h6
                style={{
                  color: 'white',
                  fontFamily:
                    pathname === ROUTE_FAQS
                      ? "'Open-sans-bold', sans-serif"
                      : "'Open-sans', sans-serif",
                }}
                onClick={() => Navigator(ROUTE_FAQS)}
              >
                {t('faqs')}
              </h6>
              {loggedUserService.isAdmin() && (
                <h6
                  style={{
                    color: 'white',
                    fontFamily:
                      pathname === ROUTE_PROFESSIONALS
                        ? "'Open-sans-bold', sans-serif"
                        : "'Open-sans', sans-serif",
                  }}
                  onClick={() => Navigator(ROUTE_PROFESSIONALS)}
                >
                  {t('professionals')}
                </h6>
              )}
              <h6
                style={{
                  color: 'white',
                  fontFamily:
                    pathname === ROUTE_USER_PROFILE
                      ? "'Open-sans-bold', sans-serif"
                      : "'Open-sans', sans-serif",
                }}
                onClick={() => Navigator(ROUTE_USER_PROFILE)}
              >
                {t('myProfile')}
              </h6>
            </>
          )}
        </Box>
        <Box display="flex" className={style.right}>
          <EmailIcon
            style={{ marginRight: '20%', cursor: 'pointer', color: '#fff' }}
            onClick={() => {
              setHelpText('')
              setHelpModalOpened(true)
            }}
          />
          <Dropdown />
          <MenuListComposition show={logout} id={props.user?.id || ''} />
        </Box>
      </Box>
      <Box className={style.bottomHeader}>
        <Box display="flex" className={style.left}>
          <h4 className={style.title}>{user?.centerID}</h4>
          <h4>
            {user?.firstName} {user?.lastName}
          </h4>
          {loggedUserService.get()?.language == 1 ? (
            <h4>
              {user?.createdAt &&
                new Date(user?.createdAt).getDate() +
                  '/' +
                  (new Date(user?.createdAt).getMonth() + 1) +
                  '/' +
                  new Date(user?.createdAt).getFullYear().toString().substring(2, 4)}
            </h4>
          ) : (
            <h4>{user?.createdAt && new Date(user?.createdAt).toLocaleDateString()}</h4>
          )}
        </Box>
      </Box>
      <Modal
        className={style.helpModal}
        open={helpModalOpened}
        onClose={() => setHelpModalOpened(false)}
        closeAfterTransition
      >
        <Box className={innerWidth < 900 ? style.helpModalBoxTablet : style.helpModalBox}>
          <Box className={style.helpModalBox2}>
            <h2 style={{ color: '#000', fontFamily: 'Open-sans, sans-serif' }}>{t('needHelp')}</h2>
          </Box>
          <p className={style.modalText}>{t('writeHereYourConsult')}:</p>
          <TextField
            id={'content'}
            key={'content'}
            name="content"
            multiline={true}
            rows={9}
            value={helpText}
            type="name"
            style={{
              marginLeft: '10%',
              width: '81%',
              marginRight: '10%',
              marginTop: '1%',
              fontFamily: 'Open-sans, sans-serif',
            }}
            required={true}
            variant={'outlined'}
            size={'small'}
            onChange={(e) => setHelpText(e.target.value)}
          />
          <Box className={style.modalButtons}>
            <AppButton
              theme={ButtonTheme.NewSecondary}
              type={'button'}
              label={t('cancel')}
              handler={() => setHelpModalOpened(false)}
            />
            <AppButton
              theme={ButtonTheme.NewPrimaryLight}
              type={'button'}
              label={t('send')}
              handler={sendHelpEmail}
            />
          </Box>
        </Box>
      </Modal>
    </>
  )
}
