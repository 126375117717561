import { FecesConsistency, FecesQuantity, Food, FoodType, Method } from '../enum/Feces'
import { v4 as uuidv4 } from 'uuid'
import { Fecal } from './Fecal'
import { Urgency, Time } from '../enum/Feces'

export interface FecalDTO {
  id: string
  patientId: string
  date: Date
  attempts: number
  effectiveness: number
  quantity: FecesQuantity
  loss: number
  consistency: FecesConsistency
  foodType: FoodType
  food: string
  foodHour: Date
  diaper: boolean
  gas: boolean
  observations: string
  urgency: Urgency
  method: Method
  time: Time
  image: string
}

export function emptyFecalDTO() {
  return {
    id: uuidv4(),
    patientId: '',
    date: new Date(),
    attempts: 0,
    effectiveness: 0,
    quantity: FecesQuantity.Few,
    loss: 0,
    consistency: FecesConsistency.HardLump,
    foodType: -1,
    food: '',
    foodHour: new Date(),
    diaper: false,
    gas: false,
    observations: '',
    urgency: Urgency.NoUrgency,
    method: Method.Natural,
    time: Time.LessThan30Minutes,
    image: '',
  }
}

export function fromModel(f: Fecal): FecalDTO {
  return {
    id: f.id,
    patientId: f.patientId,
    date: f.date,
    attempts: f.attempts,
    effectiveness: f.effectiveness,
    quantity: f.quantity,
    loss: f.loss,
    consistency: f.consistency,
    foodType: f.foodType,
    food: f.food,
    foodHour: f.foodHour,
    diaper: f.diaper,
    gas: f.gas,
    observations: f.observations,
    urgency: f.urgency,
    method: f.method,
    time: f.time,
    image: '',
  }
}
