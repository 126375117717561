import { Box } from '@mui/material'
import generic from '../../assets/generic.module.css'
import { useTranslation } from 'react-i18next'
import { Avatar, Backdrop, Fade, Grid, IconButton, Modal } from '@material-ui/core'
import { Urination } from '../../modules/urination/models/Urination'
import {
  drinkQuantity,
  drinkQuantityWithoutML,
  drinkType,
  DrinkType,
  Emptying,
  emptying,
  emptyingQuantity,
  EmptyingQuantity,
  foodTypes,
  incontinenceType,
  lossType,
  Quantity,
} from '../../modules/urination/enum/Urine'
import viewIcon from '../../assets/higea/ico-view.svg'
import React, { useState } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import { Pagination } from './Pagination'
import { Pager } from './types'
import { makeStyles } from '@material-ui/core/styles'
import { getUserContainer } from 'container/user-module'
import { LOGGED_USER_SERVICE_KEY } from 'modules/users'
import { ILoggedUserService } from 'modules/users/services/LoggedUserService'

type TableUrination = {
  items: Urination[]
  pager?: Pager | undefined
}

const { innerWidth } = window
const useStyles = makeStyles((_) => ({
  head: {
    fontWeight: 'bold',
    backgroundColor: '#FFFFFF',
    color: '#000',
    fontSize: innerWidth < 900 ? '12px' : '14px',
    padding: innerWidth < 900 ? 0 : 22,
    marginTop: '10%',
  },
  head0: {
    backgroundColor: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    borderBottom: '4.5px solid #68B3E0',
    borderTop: '1px solid #d1d1d1',
    borderLeft: '1px solid #d1d1d1',
  },
  head1: {
    fontWeight: 'bold',
    backgroundColor: '#FFFFFF',
    color: '#000',
    fontSize: innerWidth < 900 ? '12px' : '14px',
    padding: innerWidth < 900 ? 0 : 22,
    display: 'flex',
    justifyContent: 'center',
  },
  head2: {
    fontWeight: 'bold',
    backgroundColor: '#FFFFFF',
    color: '#000',
    fontSize: innerWidth < 900 ? '12px' : '14px',
    width: '20%',
    borderBottom: '4.5px solid #68B3E0',
    borderTop: '1px solid #d1d1d1',
    borderLeft: '1px solid #d1d1d1',
  },
  head3: {
    fontWeight: 'bold',
    backgroundColor: '#FFFFFF',
    color: '#000',
    fontSize: innerWidth < 900 ? '12px' : '14px',
    borderBottom: '4.5px solid #68B3E0',
    borderTop: '1px solid #d1d1d1',
    borderLeft: '1px solid #d1d1d1',
  },
  head4: {
    fontWeight: 'bold',
    backgroundColor: '#FFFFFF',
    color: '#000',
    fontSize: innerWidth < 900 ? '12px' : '14px',
    display: 'flex',
    justifyContent: 'center',
    padding: 10,
    marginTop: '20%',
  },
  back: {
    backgroundColor: '#FFFFFF',
    width: '10%',
    fontWeight: 'bold',
    color: '#000',
    fontSize: innerWidth < 900 ? '12.5px' : '14px',
    padding: innerWidth < 900 ? 0 : 22,
    display: 'flex',
    justifyContent: 'center',
    borderBottom: '4.5px solid #68B3E0',
    borderTop: '3px solid #68B3E0',
    borderLeft: '1px solid #d1d1d1',
  },
  back1: {
    fontWeight: 'bold',
    backgroundColor: '#FFFFFF',
    color: '#000',
    fontSize: innerWidth < 900 ? '9px' : '14px',
    padding: innerWidth < 900 ? 0 : 22,
    display: 'flex',
    justifyContent: 'center',
  },
  back2: {
    backgroundColor: '#FFFFFF',
    width: '10%',
    fontWeight: 'bold',
    color: '#000',
    fontSize: innerWidth < 900 ? '12.5px' : '14px',
    padding: innerWidth < 900 ? 0 : 22,
    display: 'flex',
    justifyContent: 'center',
    borderBottom: '4.5px solid #68B3E0',
    borderTop: '3px solid #68B3E0',
    borderLeft: '1px solid #68B3E0',
  },
  back3: {
    backgroundColor: '#FFFFFF',
    width: '10%',
    fontWeight: 'bold',
    color: '#000',
    fontSize: innerWidth < 900 ? '12.5px' : '14px',
    padding: innerWidth < 900 ? 0 : 22,
    display: 'flex',
    justifyContent: 'center',
    borderBottom: '4.5px solid #68B3E0',
    borderTop: '3px solid #68B3E0',
    borderLeft: '1px solid #d1d1d1',
    borderRight: '1px solid #68B3E0',
  },
  dateStyle: {
    backgroundColor: '#FFFFFF',
    width: '8.4%',
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 2,
    borderStyle: 'solid',
    borderColor: '#d1d1d1',
  },
  dateStyle2: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    marginTop: '15%',
    flexDirection: 'column',
    fontSize: innerWidth < 900 ? 10 : 15,
    marginLeft: '20%',
  },
  drinkType: {
    width: '7.4%',
    backgroundColor: '#FFFFFF',
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 2,
    borderStyle: 'solid',
    borderColor: '#d1d1d1',
  },
  boxDrinkType: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: innerWidth < 900 ? 7 : 15,
  },
  generalBox: {
    backgroundColor: '#FFFFFF',
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 2,
    borderStyle: 'solid',
    borderColor: '#d1d1d1',
  },
  dateBoxStyle: {
    marginTop: '1.7%',
    fontSize: innerWidth < 900 ? 10 : 15,
  },
  modalStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalCloseIcon: {
    marginLeft: '-0.7%',
    marginTop: '-0.6%',
    backgroundColor: 'grey',
    color: 'white',
    borderRadius: 12,
  },
  modalImg: {
    height: '38%',
    width: '34%',
  },
  generalBox2: {
    marginTop: '15%',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },
  generalBox3: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    marginTop: '15%',
    fontSize: innerWidth < 900 ? 10 : 15,
    marginLeft: '50%',
  },
  generalBox4: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    marginTop: '15%',
    fontSize: innerWidth < 900 ? 8 : 15,
    marginLeft: '50%',
  },
  avatarStyle: {
    width: innerWidth < 900 ? 18 : 30,
    height: innerWidth < 900 ? 13 : 25,
  },
}))

const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

export const TableMicturitionMonitoring = (props: TableUrination) => {
  const { t } = useTranslation()
  const [imagesModalOpened, setImagesModalOpened] = useState<boolean>(false)
  const [currentUrinationImage, setCurrentUrinationImage] = useState<string>('')

  const drinkTypeMap: { [key: string]: DrinkType } = {
    '0': DrinkType.None,
    '1': DrinkType.Water,
    '2': DrinkType.Coffee,
    '3': DrinkType.FreshDrink,
    '4': DrinkType.Alcohol,
  }

  const drinkTypesArray = props.items.map((item) =>
    item.drinkType.split(',').map((drink) => drinkTypeMap[drink.trim()])
  )

  const quantityTypeMap: { [key: string]: Quantity } = {
    '0': Quantity.Glass,
    '1': Quantity.Cup,
    '2': Quantity.Tin,
    '3': Quantity.Bottle,
  }

  const quantityTypesArray = props.items.map((item) =>
    item.drinkQuantity.split(',').map((quantity) => quantityTypeMap[quantity.trim()])
  )

  const emptyingTypeMap: { [key: string]: Emptying } = {
    '0': Emptying.None,
    '1': Emptying.Urine,
    '2': Emptying.Catheter,
  }

  const emptyingTypesArray = props.items.map((item) =>
    item.emptying.split(',').map((emptying) => emptyingTypeMap[emptying.trim()])
  )

  const emptyingQuantityMap: { [key: string]: EmptyingQuantity } = {
    '50': EmptyingQuantity.Fifty,
    '100': EmptyingQuantity.Hundred,
    '150': EmptyingQuantity.HundredFifty,
    '200': EmptyingQuantity.TwoHundred,
    '250': EmptyingQuantity.TwoHundredFifty,
    '300': EmptyingQuantity.ThreeHundred,
    '350': EmptyingQuantity.ThreeHundredFifty,
    '400': EmptyingQuantity.FourHundred,
    '450': EmptyingQuantity.FourHundredFifty,
    '500': EmptyingQuantity.FiveHundred,
    '550': EmptyingQuantity.FiveHundredFifty,
    '600': EmptyingQuantity.SixHundred,
    '650': EmptyingQuantity.SixHundredFifty,
    '700': EmptyingQuantity.SevenHundred,
    '750': EmptyingQuantity.SevenHundredFifty,
    '800': EmptyingQuantity.EightHundred,
    '850': EmptyingQuantity.EightHundredFifty,
  }

  const emptyingQuantityTypesArray = props.items.map((item) =>
    item.emptyingQuantity.split(',').map((quantity) => emptyingQuantityMap[quantity.trim()])
  )

  const handleSeeImage = (ur: Urination) => {
    if (ur.image.length > 0) {
      setCurrentUrinationImage(ur.image)
    }
    setImagesModalOpened(true)
  }

  const existDrink = (urination: Urination) => {
    const drinkTypes = urination.drinkType.split(',').map((drink) => {
      switch (drink) {
        case '1':
          return DrinkType.Water
        case '2':
          return DrinkType.Coffee
        case '3':
          return DrinkType.FreshDrink
        case '4':
          return DrinkType.Alcohol
        default:
          return DrinkType.None
      }
    })
    return drinkTypes.some((drink) => drinkType()[drink])
  }

  const existEmptying = (urination: Urination) => {
    const emptyingTypes = urination.emptying.split(',').map((emp) => {
      switch (emp) {
        case '1':
          return Emptying.Urine
        case '2':
          return Emptying.Catheter
        default:
          return Emptying.None
      }
    })
    return emptyingTypes.some((emp) => emptying()[emp])
  }

  const classes = useStyles()

  const dateEqualsLastOne = (type: string, urination: Urination) => {
    const isThereDrink = existDrink(urination)
    const isThereEmptying = existEmptying(urination)

    let date1 = new Date(urination.emptyingDate)
    let date2

    switch (type) {
      case 'drink':
        return false

      case 'food':
        if (isThereDrink) {
          let date1 = new Date(urination.drinkDate)
          let date2 = new Date(urination.foodDate)
          return (
            date1.getFullYear() == date2.getFullYear() &&
            date1.getMonth() == date2.getMonth() &&
            date1.getDate() == date2.getDate() &&
            date1.getHours() == date2.getHours() &&
            date1.getMinutes() == date2.getMinutes()
          )
        } else {
          return false
        }

      case 'emptying':
        if (!existDrink && !drinkType()[urination.foodType]) {
          return false
        }

        if (drinkType()[urination.foodType]) {
          date2 = new Date(urination.foodDate)
        } else if (isThereDrink) {
          date2 = new Date(urination.drinkDate)
        }

        return (
          date1.getFullYear() == date2?.getFullYear() &&
          date1.getMonth() == date2.getMonth() &&
          date1.getDate() == date2.getDate() &&
          date1.getHours() == date2.getHours() &&
          date1.getMinutes() == date2.getMinutes()
        )
      case 'loss':
        date1 = new Date(urination.lossDate)
        if (!isThereDrink && !drinkType()[urination.foodType] && !isThereEmptying) {
          return false
        } else if (isThereEmptying) {
          date2 = new Date(urination.emptyingDate)
        } else if (drinkType()[urination.foodType]) {
          date2 = new Date(urination.foodDate)
        } else {
          date2 = new Date(urination.drinkDate)
        }

        return (
          date1.getFullYear() == date2?.getFullYear() &&
          date1.getMonth() == date2.getMonth() &&
          date1.getDate() == date2.getDate() &&
          date1.getHours() == date2.getHours() &&
          date1.getMinutes() == date2.getMinutes()
        )
      default:
        return false
    }
  }

  return (
    <>
      <Box
        className={generic.pageContainer}
        style={{ marginLeft: '-0.2%', fontSize: innerWidth < 900 ? 12 : 18 }}
      >
        <Box sx={{ flexGrow: 1, width: '100%', padding: '10px', paddingBottom: 0 }}>
          <Grid container spacing={2}>
            <Grid item className={classes.head0} style={{ width: '10%' }}>
              <Box className={classes.head}>{t('date')}</Box>
            </Grid>
            <Grid item xs={9}>
              <Box>
                <Grid container spacing={2}>
                  <Grid item className={classes.head2}>
                    <Box className={classes.head1}>{t('drinkType')}</Box>
                  </Grid>
                  <Grid item className={classes.head2}>
                    <Box className={classes.head1}>{t('food')}</Box>
                  </Grid>
                  <Grid item className={classes.head2}>
                    <Box className={classes.head1}>{t('emptying')}</Box>
                  </Grid>
                  <Grid item className={classes.head2}>
                    <Box className={classes.head1}>{t('urgency')}</Box>
                  </Grid>
                  <Grid item className={classes.head2}>
                    <Box className={classes.head1}>{t('loss')}</Box>
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Grid container spacing={2}>
                  <Grid item className={classes.back2}>
                    <Box className={classes.back1}>{t('type')}</Box>
                  </Grid>
                  <Grid item className={classes.back}>
                    <Box className={classes.back1}>{t('quantity')}</Box>
                  </Grid>
                  <Grid item className={classes.back}>
                    <Box className={classes.back1}>{t('type')}</Box>
                  </Grid>
                  <Grid item className={classes.back}>
                    <Box className={classes.back1}>{t('waterQuantity')}</Box>
                  </Grid>
                  <Grid item className={classes.back}>
                    <Box className={classes.back1}>{t('urinated')}</Box>
                  </Grid>
                  <Grid item className={classes.back}>
                    <Box className={classes.back1}>{t('sounded')}</Box>
                  </Grid>
                  <Grid item className={classes.back} style={{ width: '20%' }}>
                    <Box className={classes.back1}>{t('0-4')}</Box>
                  </Grid>
                  <Grid item className={classes.back}>
                    <Box className={classes.back1}>{t('response')}</Box>
                  </Grid>
                  <Grid item className={classes.back3}>
                    <Box className={classes.back1}>{t('type')}</Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item className={classes.head3} style={{ width: '6.6%' }}>
              <Box className={classes.head4}>{t('image')}</Box>
            </Grid>
            <Grid item xs={1} className={classes.head3}>
              <Box className={classes.head4}>Obs.</Box>
            </Grid>
          </Grid>
        </Box>
        {props.items.map((urination, i) => (
          <Box
            sx={[
              { flexGrow: 1, width: '100%', padding: '10px' },
              i == 0 ? { paddingTop: 1.8 } : { marginTop: '-5px' },
            ]}
          >
            <Grid container spacing={2}>
              <Grid item className={classes.dateStyle}>
                <Box className={classes.dateStyle2}>
                  {loggedUserService.get()?.language == 1 ? (
                    <Box>
                      {(new Date(urination.date).getDate() <= 9 ? '0' : '') +
                        new Date(urination.date).getDate() +
                        '/' +
                        (new Date(urination.date).getMonth() <= 9 ? '0' : '') +
                        (new Date(urination.date).getMonth() + 1) +
                        '/' +
                        new Date(urination.date).getFullYear().toString()}
                    </Box>
                  ) : (
                    <Box>{new Date(urination.date).toLocaleDateString()}</Box>
                  )}
                  {existDrink(urination) && (
                    <Box className={classes.dateBoxStyle}>
                      {new Date(urination.drinkDate).getHours() +
                        ':' +
                        (new Date(urination.drinkDate).getMinutes() < 10 ? '0' : '') +
                        new Date(urination.drinkDate).getMinutes() +
                        ' h'}
                    </Box>
                  )}
                  {foodTypes()[urination.foodType] && (
                    <Box className={classes.dateBoxStyle}>
                      {!dateEqualsLastOne('food', urination) && (
                        <>
                          {new Date(urination.foodDate).getHours() +
                            ':' +
                            (new Date(urination.foodDate).getMinutes() < 10 ? '0' : '') +
                            new Date(urination.foodDate).getMinutes() +
                            ' h'}
                        </>
                      )}
                    </Box>
                  )}
                  {existEmptying(urination) && (
                    <Box className={classes.dateBoxStyle}>
                      {!dateEqualsLastOne('emptying', urination) && (
                        <>
                          {new Date(urination.emptyingDate).getHours() +
                            ':' +
                            (new Date(urination.emptyingDate).getMinutes() < 10 ? '0' : '') +
                            new Date(urination.emptyingDate).getMinutes() +
                            ' h'}
                        </>
                      )}
                    </Box>
                  )}
                  {lossType()[urination.loss] && (
                    <Box className={classes.dateBoxStyle}>
                      {!dateEqualsLastOne('loss', urination) && (
                        <>
                          {new Date(urination.lossDate).getHours() +
                            ':' +
                            (new Date(urination.lossDate).getMinutes() < 10 ? '0' : '') +
                            new Date(urination.lossDate).getMinutes() +
                            ' h'}
                        </>
                      )}
                    </Box>
                  )}
                </Box>
              </Grid>
              <Grid item className={classes.drinkType} style={{ display: 'flex' }}>
                <Box
                  key={i}
                  display={'flex'}
                  flexDirection={'column'}
                  className={classes.boxDrinkType}
                  style={{ marginLeft: '30%', width: '1%' }}
                >
                  {drinkTypesArray[i]
                    .filter((d) => d !== DrinkType.None)
                    .map((drink, i) => (
                      <Box key={i}>{drinkType()[drink]}</Box>
                    ))}
                </Box>
              </Grid>
              <Grid item className={classes.generalBox} style={{ width: '7.5%', display: 'flex' }}>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  className={classes.generalBox3}
                  mt={0}
                >
                  {existDrink(urination) &&
                    quantityTypesArray[i].map((quantity, index) =>
                      quantity !== undefined ? (
                        window.innerWidth < 1580 ? (
                          <Box key={index}>{drinkQuantityWithoutML()[quantity]}</Box>
                        ) : (
                          <Box key={index}>{drinkQuantity()[quantity]}</Box>
                        )
                      ) : (
                        <Box key={index} style={{ visibility: 'hidden' }}>
                          {drinkQuantityWithoutML()[Quantity.Tin]}
                        </Box>
                      )
                    )}
                </Box>
              </Grid>
              <Grid item className={classes.generalBox} style={{ width: '7.5%' }}>
                <Box className={classes.generalBox4}>{foodTypes()[urination.foodType]}</Box>
              </Grid>
              <Grid item className={classes.generalBox} style={{ width: '7.45%' }}>
                <Box className={classes.generalBox3}>
                  {foodTypes()[urination.foodType] && urination.waterQuantity.toFixed(2) + ' ml'}
                </Box>
              </Grid>
              <Grid item className={classes.generalBox} style={{ width: '7.5%' }}>
                <Box className={classes.generalBox3}>
                  {emptyingTypesArray[i].map((type, index) => {
                    if (type === Emptying.Urine) {
                      return (
                        <Box key={index} mb={1}>
                          {emptyingQuantity()[emptyingQuantityTypesArray[i][index]]}
                        </Box>
                      )
                    }
                  })}
                </Box>
              </Grid>
              <Grid item className={classes.generalBox} style={{ width: '7.5%' }}>
                <Box className={classes.generalBox3}>
                  {emptyingTypesArray[i].map((type, index) => {
                    if (type === Emptying.Catheter) {
                      return (
                        <Box key={index} mb={1}>
                          {emptyingQuantity()[emptyingQuantityTypesArray[i][index]]}
                        </Box>
                      )
                    }
                  })}
                </Box>
              </Grid>

              <Grid item className={classes.generalBox} style={{ width: '15%' }}>
                <Box
                  style={{
                    marginTop: '7%',
                    display: 'flex',
                    justifyContent: 'center',
                    textAlign: 'center',
                    fontSize: innerWidth < 900 ? 10 : 15,
                    marginLeft: '25%',
                  }}
                >
                  {Number(urination.urgency) != -1 && urination.urgency}
                </Box>
              </Grid>
              <Grid item className={classes.generalBox} style={{ width: '7.5%' }}>
                <Box className={classes.generalBox3}>{lossType()[urination.loss]}</Box>
              </Grid>
              <Grid item className={classes.generalBox} style={{ width: '8.2%' }}>
                <Box
                  className={classes.generalBox2}
                  style={{ fontSize: innerWidth < 900 ? 7 : 13.5, marginLeft: '40%' }}
                >
                  {incontinenceType()[urination.incontinence]}
                </Box>
              </Grid>
              <Grid item className={classes.generalBox} style={{ width: '7.8%' }}>
                <Box className={classes.generalBox2}>
                  {urination.image != '' && (
                    <IconButton
                      key={urination.id}
                      id={'idUrination'}
                      onClick={() => handleSeeImage(urination)}
                    >
                      <Avatar src={viewIcon} className={classes.avatarStyle} />
                    </IconButton>
                  )}
                </Box>
              </Grid>
              <Grid item className={classes.generalBox} style={{ width: '8.25%' }}>
                <Box
                  className={classes.generalBox2}
                  style={{ fontSize: innerWidth < 900 ? 8 : 13.5 }}
                >
                  {urination.observations}
                </Box>
              </Grid>
            </Grid>
          </Box>
        ))}
        <Box
          display={'flex'}
          justifyContent={'flex-end'}
          style={{ backgroundColor: 'white', marginTop: '-0.3%' }}
        >
          {props.pager && <Pagination {...props.pager} />}
        </Box>
        <Modal
          className={classes.modalStyle}
          open={imagesModalOpened}
          onClose={() => setImagesModalOpened(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade
            in={imagesModalOpened}
            timeout={500}
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              marginTop: '35%',
            }}
          >
            <div>
              <img
                src={currentUrinationImage}
                alt="Micturition image"
                className={classes.modalImg}
              />
              <CloseIcon
                color="primary"
                className={classes.modalCloseIcon}
                onClick={() => setImagesModalOpened(false)}
              />
            </div>
          </Fade>
        </Modal>
      </Box>
    </>
  )
}
