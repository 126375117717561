import { FecesConsistency, FecesQuantity, Food, FoodType, Method, Time } from '../enum/Feces'
import { FecalDTO } from './FecalDTO'
import { Urgency } from '../enum/Feces'

export class Fecal {
  private readonly _id: string
  private readonly _patientId: string
  private readonly _date: Date
  private readonly _attempts: number
  private readonly _effectiveness: number
  private readonly _quantity: FecesQuantity
  private readonly _loss: number
  private readonly _consistency: FecesConsistency
  private readonly _foodType: FoodType
  private readonly _food: string
  private readonly _foodHour: Date
  private readonly _diaper: boolean
  private readonly _gas: boolean
  private readonly _observations: string
  private readonly _urgency: Urgency
  private readonly _method: Method
  private readonly _time: Time
  private readonly _image: string

  constructor(p: FecalDTO) {
    this._id = p.id
    this._patientId = p.patientId
    this._date = p.date
    this._attempts = p.attempts
    this._effectiveness = p.effectiveness
    this._quantity = p.quantity
    this._loss = p.loss
    this._consistency = p.consistency
    this._foodType = p.foodType
    this._food = p.food
    this._foodHour = p.foodHour
    this._diaper = p.diaper
    this._gas = p.gas
    this._observations = p.observations
    this._urgency = p.urgency
    this._method = p.method
    this._time = p.time
    this._image = p.image
  }

  get id(): string {
    return this._id
  }

  get image(): string {
    return this._image
  }

  get patientId(): string {
    return this._patientId
  }

  get date(): Date {
    return this._date
  }

  get attempts(): number {
    return this._attempts
  }

  get effectiveness(): number {
    return this._effectiveness
  }

  get quantity(): FecesQuantity {
    return this._quantity
  }

  get loss(): number {
    return this._loss
  }

  get consistency(): FecesConsistency {
    return this._consistency
  }

  get foodType(): FoodType {
    return this._foodType
  }

  get food(): string {
    return this._food
  }

  get foodHour(): Date {
    return this._foodHour
  }

  get diaper(): boolean {
    return this._diaper
  }

  get gas(): boolean {
    return this._gas
  }

  get observations(): string {
    return this._observations
  }

  get urgency(): Urgency {
    return this._urgency
  }

  get method(): Method {
    return this._method
  }

  get time(): Time {
    return this._time
  }
}

export interface FecalQuery {
  patientId: string
  date: Date
}

export function toModel(f: FecalDTO): Fecal {
  return new Fecal(f)
}
