import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core'
import { FormLabel } from '@mui/material'
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers'
import { useTranslation } from 'react-i18next'
import {
  AppointmentDTO,
  emptyAppointmentDTO,
} from '../../modules/appointments/models/AppointmentDTO'
import Paper from '@material-ui/core/Paper'
import TextField from '@mui/material/TextField'
import stylesAppoint from './Appointments.module.css'
import { Box } from '@mui/material'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

type AppointmentProps = {
  data: AppointmentDTO | undefined
  handleClose: () => void
  handleSaveEdit: (a: AppointmentDTO) => void
}

export const Editor = ({ data, handleClose, handleSaveEdit }: AppointmentProps) => {
  const { t } = useTranslation()

  const [appointment, setAppointment] = useState<AppointmentDTO>(
    data || emptyAppointmentDTO('', '')
  )
  const [DateTimePickerError, setDateTimePickerError] = useState<string>('')

  useEffect(() => {
    if (!data) return
    setAppointment(data)
    setAppointment(
      Object.assign(
        { ...appointment },
        {
          date: data.date,
          title: data.title,
          place: data.place,
          patientID: data.patientID,
          userID: data.userID,
          id: data.id,
          urlVideoConf: data.urlVideoConf,
        }
      )
    )
  }, [])

  return (
    <>
      <Grid container spacing={3} style={{ paddingTop: 45, paddingLeft: 50 }}>
        <Grid item xs={3}>
          <Paper style={{ boxShadow: 'none' }}>
            <FormLabel
              sx={{
                '.MuiFormLabel-root': {
                  fontFamily: 'Open-sans, sans-serif',
                },
              }}
              style={{
                display: 'inline-block',
                marginBottom: 10,
                fontSize: 14,
                color: '#000',
                fontFamily: 'Open-sans, sans-serif',
              }}>
              {t('visitDate')}
            </FormLabel>
            <br />
            {/*<KeyboardDatePicker
              style={{ width: '200px' }}
              key={'visitDate'}
              id={'visitDate'}
              format="DD/MM/YYYY"
              className="form-control"
              variant="inline"
              inputVariant={'outlined'}
              size={'small'}
              autoOk
              value={appointment?.date}
              onChange={(e) => {
                if (e) setAppointment(Object.assign({ ...appointment }, { date: e.toDate() }))
              }}
            />*/}
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
              <TimePicker
                key={'finishHour'}
                PaperProps={{
                  sx: {
                    '& .MuiButtonBase-root': {
                      fontFamily: 'Open-sans, sans-serif',
                    },
                  },
                }}
                //className={styles.hourPicker}
                InputProps={{ disableUnderline: true, sx: { fontFamily: 'Open-sans, sans-serif' } }}
                onError={(reason, value) => {
                  switch (reason) {
                    case 'invalidDate':
                      setDateTimePickerError(t('invalidDateMessage'))
                      break
                  }
                }}
                renderInput={(props) => (
                  <TextField
                    sx={{
                      '.css-1x51dt5-MuiInputBase-input-MuiInput-input': {
                        fontFamily: 'Open-sans, sans-serif',
                      },
                    }}
                    style={{ marginBottom: 8 }}
                    variant={'standard'}
                    helperText={props.error && DateTimePickerError}
                    {...props}
                  />
                )}
                inputFormat="HH:mm"
                ampm={false}
                onChange={(e) => {
                  if (e) setAppointment(Object.assign({ ...appointment }, { date: e }))
                }}
                value={appointment?.date}
              />
            </LocalizationProvider>
          </Paper>
        </Grid>
        <Grid item xs={6} style={{ marginLeft: '-6%' }}>
          <Paper style={{ boxShadow: 'none' }}>
            <FormLabel
              style={{
                display: 'inline-block',
                marginBottom: 10,
                fontSize: 14,
                color: '#000',
                fontFamily: 'Open-sans, sans-serif',
              }}>
              {t('hourDate')}
            </FormLabel>
            <br />
            <KeyboardTimePicker
              style={{ width: '200px' }}
              key={'visitTime'}
              id={'visitTime'}
              format="HH:mm"
              className="form-control"
              variant="inline"
              inputVariant={'outlined'}
              size={'small'}
              autoOk
              value={appointment?.date}
              onChange={(e) => {
                if (e) setAppointment(Object.assign({ ...appointment }, { date: e.toDate() }))
              }}
            />
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={3} style={{ paddingTop: 45, paddingLeft: 50 }}>
        <Grid item xs={5}>
          <Paper style={{ boxShadow: 'none' }}>
            <FormLabel
              style={{
                display: 'inline-block',
                marginBottom: 10,
                fontSize: 14,
                color: '#000',
                fontFamily: 'Open-sans, sans-serif',
              }}>
              {t('reason')}
            </FormLabel>
          </Paper>
          <Paper style={{ boxShadow: 'none' }}>
            <TextField
              onChange={(e) => {
                setAppointment(Object.assign({ ...appointment }, { title: e.target.value }))
              }}
              id="outlined-basic"
              variant="outlined"
              value={appointment?.title}
              required
              inputProps={{
                style: {
                  height: 5,
                  width: 370,
                  fontFamily: 'Open-sans, sans-serif',
                },
              }}
            />
          </Paper>
        </Grid>
        <Grid item xs={5}>
          <Paper style={{ boxShadow: 'none' }}>
            <FormLabel
              style={{
                display: 'inline-block',
                marginBottom: 10,
                fontSize: 14,
                color: '#000',
                fontFamily: 'Open-sans, sans-serif',
              }}>
              {t('place')}
            </FormLabel>
          </Paper>
          <Paper style={{ boxShadow: 'none' }}>
            <TextField
              onChange={(e) => {
                setAppointment(Object.assign({ ...appointment }, { place: e.target.value }))
              }}
              id="outlined-basic"
              variant="outlined"
              value={appointment?.place}
              required
              inputProps={{
                style: {
                  height: 5,
                  width: 370,
                  fontFamily: 'Open-sans, sans-serif',
                },
              }}
            />
          </Paper>
        </Grid>
      </Grid>
      <Box className={stylesAppoint.buttonContainer}>
        <AppButton
          theme={ButtonTheme.NewSecondary}
          type={'button'}
          label={t('cancel')}
          handler={() => handleClose()}
        />
        <AppButton
          theme={ButtonTheme.NewPrimaryLight}
          type={'button'}
          label={t('accept')}
          handler={() => appointment && handleSaveEdit(appointment)}
        />
      </Box>
    </>
  )
}
