import generic from '../../assets/generic.module.css'
import { Alert, AppBar, Box, Button, Dialog, Radio, Tab, Tabs, Typography } from '@mui/material'
import style from '../statistics/Stats.module.css'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { AppTable, Field } from '../../components/table'
import { Actions, Sort } from '../../components/table/types'
import { Appointment, AppointmentQuery } from '../../modules/appointments/models/Appointment'
import { Query, QueryParam, SortParam } from '../../common/api/Query'
import { getAppointmentContainer } from '../../container/appointment-module'
import { APPOINTMENTS_SERVICE_KEY } from '../../modules/appointments'
import { AppointmentService } from '../../modules/appointments/services/AppointmentService'
import {
  AppointmentDTO,
  emptyAppointmentDTO,
} from '../../modules/appointments/models/AppointmentDTO'
import 'react-datepicker/dist/react-datepicker.css'
import {
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormLabel,
  RadioGroup,
} from '@material-ui/core'
import TextField from '@mui/material/TextField'
import { ILoggedUserService } from '../../modules/users/services/LoggedUserService'
import { getUserContainer } from '../../container/user-module'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { v4 as uuidv4 } from 'uuid'
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers'
import { AppButton, ButtonTheme } from 'components/app-button/AppButton'
import { navigate } from '@reach/router'
import { ROUTE_PATIENTS_ID } from '../../routes/routes-constants'
import stylesAppoint from './Appointments.module.css'
import editIcon from '../../assets/table_icons/ico-edit.svg'
import deleteIcon from '../../assets/table_icons/ico-eliminar.svg'
import { Editor } from './Editor'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'

const appointmentService =
  getAppointmentContainer().get<AppointmentService>(APPOINTMENTS_SERVICE_KEY)
const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
type PatientAppointmentsProps = {
  id: string
}

export const Table = (p: PatientAppointmentsProps) => {
  const { t } = useTranslation()
  const [tabValue, setTabValue] = useState<number>(0)
  const [items, setItems] = useState<Appointment[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [appointment, setAppointment] = useState<AppointmentDTO>(
    emptyAppointmentDTO(loggedUserService.get()?.id || '', p.id)
  )
  const [appointmentToEdit, setAppointmentToEdit] = useState<AppointmentDTO | undefined>()
  const [openEdit, setOpenEdit] = useState<boolean>(false)
  const [isVideoconference, setVideoconference] = useState<boolean>(true)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const { innerWidth } = window

  const [sort, setSort] = useState<SortParam<Appointment>>({
    field: 'title',
    desc: true,
  })
  const [DateTimePickerError, setDateTimePickerError] = useState<string>('')

  useEffect(() => {
    if (!isLoading) {
      return
    }
    appointmentService
      .getFilteredList(
        new Query({
          query: [new QueryParam<AppointmentQuery>('patientID', p.id)],
          sort: [{ field: sort.field, desc: sort.desc }],
        })
      )
      .subscribe((res) => {
        setItems(res.items)
        setIsLoading(false)
      })
  }, [isLoading])

  const handleTabChange = (event: React.ChangeEvent<{}>, tabNumber: number) => {
    setTabValue(tabNumber)
  }

  const openVideoConf = (url: string) => {
    let win = window.open(url, '_blank')
    win?.focus()
  }

  const fields: Field<Appointment>[] = [
    {
      sortable: true,
      label: t('reason'),
      name: 'title',
    },
    {
      sortable: true,
      label: t('place'),
      name: 'place',
    },
    {
      sortable: true,
      label: t('visitDate'),
      name: 'date',
      renderFunc: (f, i) =>
        loggedUserService.get()?.language == 1
          ? (new Date(i.date).getDate() <= 9 ? '0' : '') +
            new Date(i.date).getDate() +
            '/' +
            (new Date(i.date).getMonth() + 1 <= 9 ? '0' : '') +
            (new Date(i.date).getMonth() + 1) +
            '/' +
            new Date(i.date).getFullYear().toString() +
            ' ' +
            new Date(i.date)
              .toLocaleTimeString()
              .substring(0, new Date(i.date).toLocaleTimeString().length - 3) +
            ' h'
          : new Date(i.date).toLocaleDateString('es', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            }) +
            ' ' +
            new Date(i.date)
              .toLocaleTimeString()
              .substring(0, new Date(i.date).toLocaleTimeString().length - 3) +
            ' h',
    },
    {
      sortable: false,
      label: t('videoconference'),
      name: 'urlVideoConf',
      renderFunc: (f, i) => (
        <Button onClick={() => openVideoConf(i.urlVideoConf)}>{i.urlVideoConf}</Button>
      ),
    },
  ]

  const handleSubmit = () => {
    let unFilled = ''

    if (appointment.title == '') {
      let space = unFilled == '' ? ': ' : ', '
      unFilled = unFilled + space + t('reason')
    }

    if (appointment.place == '') {
      let space = unFilled == '' ? ': ' : ', '
      unFilled = unFilled + space + t('place')
    }

    setErrorMessage(unFilled)

    if (unFilled !== '') {
      setErrorMessage(unFilled)
    } else {
      let newAppointment = appointment
      if (isVideoconference) {
        newAppointment = Object.assign(
          { ...appointment },
          {
            urlVideoConf:
              'https://meet.jit.si/' +
              new Date(appointment.date).toLocaleDateString().replaceAll('/', '-') +
              uuidv4() +
              '-' +
              appointment.userID +
              '-' +
              appointment.patientID,
          }
        )
      }

      appointmentService.add(newAppointment).subscribe(() => {
        setAppointment(emptyAppointmentDTO(loggedUserService.get()?.id || '', p.id))
        setIsLoading(true)
      })
    }
  }

  const sortable: Sort<Appointment> = {
    name: sort.field,
    direction: sort.desc ? 'desc' : 'asc',
    handleSort: (field) => {
      setSort({ field: field, desc: sort.field === field ? !sort.desc : true })
      setIsLoading(true)
    },
  }

  const editAppointment = (a: AppointmentDTO) => {
    setAppointmentToEdit(a)
    setOpenEdit(true)
  }

  const deleteAppointment = (a: AppointmentDTO) => {
    appointmentService.delete(a.id).subscribe(() => {
      setIsLoading(true)
    })
  }

  const actions: Actions<AppointmentDTO> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: editAppointment,
        icon: editIcon,
        label: t('edit'),
      },
      {
        handler: deleteAppointment,
        icon: deleteIcon,
        label: t('delete'),
      },
    ],
  }

  const handleCloseEdit = () => {
    setAppointmentToEdit(undefined)
    setOpenEdit(false)
  }

  const handleSaveEdit = (a: AppointmentDTO | undefined) => {
    if (a && a?.title != '' && a?.place != '') {
      appointmentService.update(a).subscribe(() => {
        setAppointmentToEdit(undefined)
        setOpenEdit(false)
        setIsLoading(true)
      })
    }
  }

  return (
    <>
      <Dialog open={openEdit} onClose={handleCloseEdit} fullWidth maxWidth="lg">
        <DialogTitle className={stylesAppoint.title}>{t('editAppointment')}</DialogTitle>
        <DialogContent>
          <Editor
            data={appointmentToEdit}
            handleClose={handleCloseEdit}
            handleSaveEdit={handleSaveEdit}
          />
        </DialogContent>
      </Dialog>

      <Box className={stylesAppoint.buttonContainer}>
        <AppButton
          theme={ButtonTheme.NewPrimaryLightWithoutWidth}
          type={'button'}
          label={t('returnToPatient')}
          handler={() => navigate(ROUTE_PATIENTS_ID.replace(':id', `${p.id}`))}
        />
      </Box>
      <Box className={generic.pageContainer}>
        {errorMessage && (
          <Box mb={3}>
            <Alert severity="warning" key="errorMessage" id="errorMessage">
              {t('unfilledFields')}
              {errorMessage}
            </Alert>
          </Box>
        )}
        <Box className={style.tabContainer}>
          <AppBar position="static" className={style.caltabs}>
            <Tabs value={tabValue} onChange={handleTabChange} className={style.caltabs}>
              <Tab
                label={t('AddAppointment')}
                className={tabValue === 0 ? style.parsetabsActive : style.parsetabs}
              />
            </Tabs>
          </AppBar>
          <Box>
            {!isLoading && (
              <>
                <Grid
                  container
                  spacing={3}
                  style={{ paddingTop: 45, paddingLeft: innerWidth < 900 ? 35 : 50 }}
                >
                  <Grid item xs={3} style={{ marginRight: innerWidth < 900 ? '8%' : '' }}>
                    <Paper style={{ boxShadow: 'none' }}>
                      <FormLabel
                        style={{
                          display: 'inline-block',
                          marginBottom: 10,
                          fontSize: 14,
                          color: '#000',
                          fontFamily: 'Open-sans, sans-serif',
                        }}
                      >
                        {t('visitDate')}
                      </FormLabel>
                      <br />
                      <KeyboardDatePicker
                        style={{ width: '200px' }}
                        InputProps={{ style: { fontFamily: 'Open-sans, sans-serif' } }}
                        key={'visitDate'}
                        id={'visitDate'}
                        format="DD/MM/YYYY"
                        className="form-control"
                        variant="inline"
                        inputVariant={'outlined'}
                        size={'small'}
                        autoOk
                        value={appointment.date}
                        onChange={(e) => {
                          if (e) setAppointment(Object.assign({ ...appointment }, { date: e }))
                        }}
                      />
                    </Paper>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    style={{ marginLeft: '2%', marginRight: innerWidth < 900 ? '10%' : '' }}
                  >
                    <Paper style={{ boxShadow: 'none' }}>
                      <FormLabel
                        style={{
                          display: 'inline-block',
                          marginBottom: 10,
                          fontSize: 14,
                          color: '#000',
                          fontFamily: 'Open-sans, sans-serif',
                        }}
                      >
                        {t('hourDate')}
                      </FormLabel>
                      <br />
                      {/*<KeyboardTimePicker
                        style={{ width: '200px' }}
                        InputProps={{ style: { fontFamily: 'Open-sans, sans-serif' } }}
                        key={'visitTime'}
                        id={'visitTime'}
                        format="HH:mm"
                        className="form-control"
                        variant="inline"
                        inputVariant={'outlined'}
                        size={'small'}
                        autoOk
                        value={appointment.date}
                        onChange={(e) => {
                          if (e) setAppointment(Object.assign({ ...appointment }, { date: e }))
                        }}
                      />*/}
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                        <TimePicker
                          key={'finishHour'}
                          PaperProps={{
                            sx: {
                              '& .MuiButtonBase-root': {
                                fontFamily: 'Open-sans, sans-serif',
                              },
                            },
                          }}
                          //className={styles.hourPicker}
                          InputProps={{
                            disableUnderline: true,
                            sx: { fontFamily: 'Open-sans, sans-serif' },
                          }}
                          onError={(reason, value) => {
                            switch (reason) {
                              case 'invalidDate':
                                setDateTimePickerError(t('invalidDateMessage'))
                                break
                            }
                          }}
                          renderInput={(props) => (
                            <TextField
                              sx={{
                                '.css-1x51dt5-MuiInputBase-input-MuiInput-input': {
                                  fontFamily: 'Open-sans, sans-serif',
                                },
                              }}
                              style={{ marginBottom: 8, width: '200px' }}
                              variant={'outlined'}
                              size={'small'}
                              helperText={props.error && DateTimePickerError}
                              {...props}
                            />
                          )}
                          inputFormat="HH:mm"
                          ampm={false}
                          onChange={(e) => {
                            if (e) setAppointment(Object.assign({ ...appointment }, { date: e }))
                          }}
                          value={appointment.date}
                        />
                      </LocalizationProvider>
                    </Paper>
                  </Grid>
                  <Grid item xs={3}>
                    <Paper style={{ boxShadow: 'none' }}>
                      <FormLabel
                        style={{
                          display: 'inline-block',
                          marginBottom: 10,
                          fontSize: 14,
                          color: '#000',
                          fontFamily: 'Open-sans, sans-serif',
                        }}
                      >
                        {t('videoconference')}
                      </FormLabel>
                      <br />
                      <Box display={'flex'}>
                        <Box style={{ display: 'flex', marginRight: 19 }}>
                          <Typography
                            style={{
                              marginRight: 4,
                              fontFamily: 'Open-sans, sans-serif',
                              color: '#000',
                            }}
                          >
                            {t('yes')}
                          </Typography>
                          <input
                            type="radio"
                            name={t('yes')}
                            checked={isVideoconference}
                            onChange={() => setVideoconference(true)}
                          />
                        </Box>
                        <Box style={{ display: 'flex' }}>
                          <Typography
                            style={{
                              marginRight: 4,
                              fontFamily: 'Open-sans, sans-serif',
                              color: '#000',
                            }}
                          >
                            {t('no')}
                          </Typography>
                          <input
                            type="radio"
                            name={t('no')}
                            checked={!isVideoconference}
                            onChange={() => setVideoconference(false)}
                          />
                        </Box>
                      </Box>
                    </Paper>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={3}
                  style={{
                    paddingTop: 45,
                    paddingLeft: innerWidth < 900 ? 35 : 50,
                    display: innerWidth < 900 ? 'flex' : '',
                    justifyContent: innerWidth < 900 ? 'space-between' : '',
                    fontFamily: 'Open-sans, sans-serif',
                  }}
                >
                  <Grid item xs={6}>
                    <Paper style={{ boxShadow: 'none' }}>
                      <FormLabel
                        style={{
                          display: 'inline-block',
                          marginBottom: 10,
                          fontSize: 14,
                          color: '#000',
                          fontFamily: 'Open-sans, sans-serif',
                        }}
                      >
                        {t('reason')}
                      </FormLabel>
                    </Paper>
                    <Paper style={{ boxShadow: 'none' }}>
                      <TextField
                        onChange={(e) =>
                          setAppointment(
                            Object.assign({ ...appointment }, { title: e.target.value })
                          )
                        }
                        id="outlined-basic"
                        variant="outlined"
                        required
                        inputProps={{
                          style: {
                            height: 5,
                            width: innerWidth < 900 ? 250 : 470,
                            fontFamily: 'Open-sans, sans-serif',
                          },
                        }}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={6} style={{ marginLeft: '-6%' }}>
                    <Paper style={{ boxShadow: 'none' }}>
                      <FormLabel
                        style={{
                          display: 'inline-block',
                          marginBottom: 10,
                          fontSize: 14,
                          color: '#000',
                          fontFamily: 'Open-sans, sans-serif',
                        }}
                      >
                        {t('place')}
                      </FormLabel>
                    </Paper>
                    <Paper style={{ boxShadow: 'none' }}>
                      <TextField
                        onChange={(e) =>
                          setAppointment(
                            Object.assign({ ...appointment }, { place: e.target.value })
                          )
                        }
                        id="outlined-basic"
                        variant="outlined"
                        required
                        inputProps={{
                          style: {
                            height: 5,
                            width: innerWidth < 900 ? 250 : 470,
                            fontFamily: 'Open-sans, sans-serif',
                          },
                        }}
                      />
                    </Paper>
                  </Grid>
                </Grid>
              </>
            )}
            <Grid
              container
              spacing={3}
              style={{ paddingTop: 45, paddingLeft: innerWidth < 900 ? 35 : 50 }}
            >
              <Grid item xs={6}>
                <Paper style={{ boxShadow: 'none' }}>
                  <AppButton
                    label={t('add')}
                    theme={ButtonTheme.NewPrimaryLight}
                    handler={handleSubmit}
                    type={'button'}
                  />
                </Paper>
              </Grid>
            </Grid>
            <FormLabel
              style={{
                display: 'inline-block',
                paddingTop: 55,
                fontSize: 22,
                paddingLeft: 50,
                color: '#000',
                fontFamily: 'Open-sans, sans-serif',
              }}
            >
              {t('datesHistory')}
            </FormLabel>
            <Box
              style={{
                paddingLeft: innerWidth < 900 ? 35 : 50,
                paddingRight: 50,
                marginTop: -30,
                paddingBottom: 30,
                fontFamily: 'Open-sans, sans-serif',
              }}
            >
              <AppTable
                items={items}
                fields={fields}
                rowKeyField={'id'}
                sort={sortable}
                actions={actions}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
